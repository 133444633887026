import { CommonModel } from '@suvo-bi-core';

export class BranchModel extends CommonModel<BranchModel> {
  name!: string;
  organisationId!: string;
  tenantId!: string;
  constructor() {
    super(BranchModel);
  }
}
