import { Component } from '@angular/core';

@Component({
  selector: 'lib-verify-emergency-removal-mfa',
  templateUrl: './verify-emergency-removal-mfa.component.html',
  styleUrls: ['./verify-emergency-removal-mfa.component.scss']
})
export class VerifyEmergencyRemovalMfaComponent {
  response = 'To request an emergency removal of an unknown 2FA method, please contact mfasupport@suvotechnology.com';
  constructor() { }

}
