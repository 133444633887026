import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompletedFormService } from '../../../shared/form-definition/services/completed-form.service';
import { FormDefinitionService } from '../../../shared/form-definition/services/form-definition.service';
import { EmptyForms } from '../forms/const/empty-forms.const';

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss']
})
export class PatientSearchComponent implements OnInit {
  lookupForm: FormGroup;

  forms = EmptyForms;

  currentError;
  loading = false;
  loadingPercentage = 0;
  itemsToLoad = 0;

  constructor(
    private fb: FormBuilder,
    private formDefinitionService: FormDefinitionService,
    private completedFormService: CompletedFormService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.lookupForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      pin: ['', [Validators.required]]
    });
  }

  async submitLookup(): Promise<void> {
    try {
      const promiseArray = [];
      for (const key in this.forms) {
        if (this.forms[key].definition) {
          promiseArray.push(this.loadCompletedForm(key));
        }
      }

      this.itemsToLoad = promiseArray.length;
      this.loading = true;
      await Promise.all(promiseArray);

      this.router.navigate(['../patient-view'], {
        relativeTo: this.activatedRoute,
        state: {
          forms: this.forms,
          backTo: 'search'
        }
      });
      this.loading = false;
      this.loadingPercentage = 0;
    } catch (err) {
      this.loading = false;
      console.log(err);
      this.currentError = err?.error;
      if (this.isArray(this.currentError?.message) && (this.currentError?.message?.length === 1)) {
        this.currentError.message = this.currentError.message[0];
      }
      console.log(this.currentError);
    }
  }

  async loadCompletedForm(key: string) {
    let lookupFormValues = {};

    let lookupFormKeys = Object.keys(this.lookupForm.value);

    for(let k of lookupFormKeys) {
      if(this.lookupForm.value.hasOwnProperty(k)){
        lookupFormValues[k] = this.lookupForm.value[k].trim();
      }
    }

    this.forms[key].answers = await this.completedFormService.getPatientCompletedForm(this.forms[key].definition._id, lookupFormValues);
    this.loadingPercentage += Math.round(100 / this.itemsToLoad);
  }

  async ngOnInit(): Promise<void> {
    for (const type in this.forms) {
      if (Object.prototype.hasOwnProperty.call(this.forms, type)) {
        this.forms[type].definition = await this.formDefinitionService.getByType(type.toUpperCase());
      }
    }
  }

  // TODO: Hacky...
  isArray(obj: any): boolean {
    if (obj) {
      return Array.isArray(obj);
    } else {
      return false;
    }
  }

  isString(obj: any): boolean {
    if (typeof obj === 'string') {
      return true;
    } else {
      return false;
    }
  }
}
