import { NgModule } from '@angular/core';
import { ApiService } from './shared/services/api/api.service';
import { CommonService } from './shared/services/common/common.service';
import { SuvoCoreClientLibComponent } from './suvo-core-client-lib.component';

@NgModule({
  declarations: [SuvoCoreClientLibComponent],
  imports: [
  ],
  providers: [
    ApiService,
    CommonService
  ],
  exports: [
    SuvoCoreClientLibComponent
  ]
})
export class SuvoCoreClientLibModule { }
