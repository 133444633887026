<div class="organisation-picker-container">
  <h2 *ngIf="currentInvitation && !currentInvitationErr">Focused Invitation</h2>
  <div *ngIf="currentInvitation && !currentInvitationErr" class="invitation current-invitation">
    <p class="invitation-description">
      {{currentInvitation.name}}
    </p>
    <div class="action-buttons">
      <button class="accept-btn" mat-raised-button color="primary" (click)="acceptInvitation(currentInvitation.organisationId)"> Accept </button>
      <button class="decline-btn" mat-raised-button (click)="declineInvitation(currentInvitation.organisationId)"> Decline </button>
    </div>
  </div>
  <div *ngIf="currentInvitationErr" class="invitation current-invitation-error">
    <p class="invitation-description">
      {{currentInvitationErr}}
    </p>
  </div>

  <h2 *ngIf="currentInvitation">Other Invitations</h2>
  <h2 *ngIf="!currentInvitation">Invitations</h2>
  <div class="invitation" *ngFor="let invitation of invitationsList">
    <p class="iinvitation-description">
      {{invitation.name}}
    </p>
    <div class="action-buttons">
      <button class="accept-btn" mat-raised-button color="primary" (click)="acceptInvitation(invitation._id)"> Accept </button>
      <button class="decline-btn" mat-raised-button (click)="declineInvitation(invitation._id)"> Decline </button>
    </div>
  </div>

  <div *ngIf="!invitationsList?.length">
    <p style="text-align: center;"> No invitations found </p>
  </div>
</div>
