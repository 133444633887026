import { Injectable } from '@angular/core';
import { ApiService, CommonService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '@suvo-bi-users';
import { DataModel } from '../../../shared/models/data-model';

@Injectable({
  providedIn: 'root'
})
export class DataApiService extends CommonService<DataModel> {
  constructor(apiService: ApiService, private settings: SuvoUsersClientLibSettingsService) {
    super(DataModel, apiService, settings.dataServiceApi, 'data');
  }
}
