import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivateComponent } from './private.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from '../../shared/modules/shared.module';
import { AppRoutingModule } from '../../app-routing.module';
import { MyAccountComponent } from './my-account/my-account.component';
import { PatientSearchComponent } from './patient-search/patient-search.component';
import { PatientCompletedFormsComponent } from './patient-completed-forms/patient-completed-forms.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { ClinicianListComponent } from './clinician-list/clinician-list.component';
import { AdminListComponent } from './admin-list/admin-list.component';
import { LoginReferrerComponent } from './login-referrer/login-referrer.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { PinDialogComponent } from './patient-list/dialogs/pin-dialog/pin-dialog.component';
import { ConfirmDeletePatientDialogComponent } from './patient-completed-forms/dialogs/confirm-delete-patient-dialog/confirm-delete-patient-dialog.component';

@NgModule({
  declarations: [
    PrivateComponent,
    DashboardComponent,
    MyAccountComponent,
    PatientSearchComponent,
    PatientCompletedFormsComponent,
    PatientListComponent,
    ClinicianListComponent,
    AdminListComponent,
    LoginReferrerComponent,
    EditProfileComponent,
    PinDialogComponent,
    ConfirmDeletePatientDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule
  ],
  providers: [],
})
export class PrivateModule { }
