import { Component, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, FormGroup, FormControl, Validators, AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { takeUntil } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.dirty && control.parent.get('newPassword').value !== control.parent.get('confirmPassword').value);
  }
}
@Component({
  selector: 'lib-reset-password-confirmation',
  templateUrl: './reset-password-confirmation.component.html',
  styleUrls: ['./reset-password-confirmation.component.scss']
})
export class ResetPasswordConfirmationComponent implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup | any;
  actionCode: string | any;
  matcher: MyErrorStateMatcher | any;
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  passRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  hide = true;
  hideConfirmPassword = true;
  constructor(
    private usersSnackbarService: UsersSnackbarService,
    private activatedRoute: ActivatedRoute,
    private angularFirebaseAuth: AngularFireAuth,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      if (!params.oobCode) {
        this.router.navigateByUrl('public/login');
      }
      this.actionCode = params.oobCode;
    });

    this.resetPasswordForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required, Validators.pattern(this.passRegex)]),
      confirmPassword: new FormControl('', [Validators.required])
    }, {
      validators: this.checkPasswordsMatch
    });
    this.matcher = new MyErrorStateMatcher();
  }

  checkPasswordsMatch = (control: AbstractControl): ValidationErrors | null => {
    if (this.resetPasswordForm) {
      return this.resetPasswordForm.get('newPassword').value === this.resetPasswordForm
        .get('confirmPassword').value ? null : { notSame: true };
    }
    return null;
  }

  onSubmit(): void {
    if (this.resetPasswordForm.valid && this.actionCode) {
      this.handleResetPassword();
    }
  }

  handleResetPassword(): void {
    this.angularFirebaseAuth.verifyPasswordResetCode(this.actionCode).then(() => {
      this.angularFirebaseAuth.confirmPasswordReset(this.actionCode, this.resetPasswordForm.value.newPassword).then((res: any) => {
        this.usersSnackbarService.open('Your Password has been changed ');
        this.router.navigateByUrl('public/login');
      }).catch((error: any) => {
        this.usersSnackbarService.open(`Something went wrong ${error}`);
        this.router.navigateByUrl('public/login');
      });
    }).catch((error: any) => {
      this.usersSnackbarService.open(`Something went wrong ${error}`);
      this.router.navigateByUrl('public/login');
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
