import { Injectable } from '@angular/core';

@Injectable()
export class SuvoUsersClientLibSettingsService {

  usersServiceApi: string;
  dataServiceApi: string;
  subscriptionServiceApi: string;
  showRegisterDoBField: boolean;
  apiKey: string;
  authDomain: string;
  title: string;
  constructor(public config: any) { }

}
