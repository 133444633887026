import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { IUser } from '../authentication/interfaces/user.interface';
import { of, Subject } from 'rxjs';
import { UsersService } from '../users/services/users.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { SuvoUsersClientLibSettingsService } from '../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { UsersSnackbarService } from '../../shared/services/users-snackbar/users-snackbar.service';

@Component({
  selector: 'lib-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {
  user!: IUser;
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  title = '';

  constructor(
    private usersSnackbarService: UsersSnackbarService,
    private usersService: UsersService,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private suvoUsersClientLibSettingsService: SuvoUsersClientLibSettingsService
  ) { }

  async ngOnInit(): Promise<void> {
    this.title = this.suvoUsersClientLibSettingsService.title;
    await this.usersService.loadUser();
    this.usersService.userSubject.pipe(takeUntil(this.unsubscribe$),
      catchError(err =>
        of(this.usersSnackbarService.open(`Something went wrong ${err}`))
        // TODO: Rewrote to stop the compiler complaining. I think this works the same way?
        // tap(theErr => this.matSnackBarService.openSnackBar(`Something went wrong ${theErr}`, 'failureCssSnackBar'))
      )
    ).subscribe((user: IUser) => {
      this.user = user;
    });
  }

  logout(): void {
    this.angularFireAuth.signOut().then(() => {
      this.router.navigateByUrl('public/login');
    }).catch(() => {
      this.usersSnackbarService.open('Something went wrong');
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
