import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrganisationService } from '../../services/organisation/organisation.service';

@Component({
  selector: 'lib-create-organisation',
  templateUrl: './create-organisation.component.html',
  styleUrls: ['./create-organisation.component.scss']
})
export class CreateOrganisationComponent implements OnInit {
  newOrganisationForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<CreateOrganisationComponent>,
    private fb: FormBuilder,
    private organisationService: OrganisationService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.newOrganisationForm = this.fb.group({
      organisationName: ['', [Validators.required]],
      firstBranchName: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  createOrganisation(): void {
    this.organisationService.post('', {
      organisation: {
        name: this.newOrganisationForm.value.organisationName
      },
      firstBranchName: this.newOrganisationForm.value.firstBranchName
    }).then((res) => {
      this.dialogRef.close(res);
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

}
