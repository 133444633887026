import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { take, switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private angularFireAuth: AngularFireAuth) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.angularFireAuth.idToken.pipe(
      take(1),
      switchMap((idToken: string | null) => {
        let clone = req.clone();
        if (idToken) {
          clone = clone.clone({ headers: req.headers.set('Authorization', `${idToken}`) });
        }
        console.log('INTERCEPTOR TOKEN IS', idToken)
        return next.handle(clone);
      })
    );
  }
}
