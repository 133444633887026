import { CommonModel } from '@suvo-bi-core';

export class CompletedFormModel extends CommonModel<CompletedFormModel> {
  completedFormValues: [{
    formControlId: string;
    value: any;
  }];
  constructor() {
    super(CompletedFormModel);
  }
}
