import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdentityService } from '../../../../../shared/services/identity/identity.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// TODO: Only import the components we need, not the whole thing
import firebase from 'firebase/app';
import 'firebase/auth';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';

@Component({
  selector: 'lib-add-new-mfa',
  templateUrl: './add-new-mfa.component.html',
  styleUrls: ['./add-new-mfa.component.scss']
})
export class AddNewMfaComponent implements OnInit, AfterViewInit {

  reCaptchaObj: firebase.auth.RecaptchaVerifier;
  localVerifyId: string;
  reEnterPassword: string;
  firebaseUser: firebase.User;
  addingMfaStage = 1;

  errMessage = '';

  addingNewMFAForm = new FormGroup({
    mfaFactorName: new FormControl('', Validators.required),
    mfaPhoneNumber: new FormControl('', [Validators.required, Validators.pattern(new RegExp(/^\+(?:[0-9]?){6,14}[0-9]$/))]),
    reEnterPassword: new FormControl('', Validators.required)
  });

  confirmationCodeForm = new FormGroup({
    localVerifyCode: new FormControl('', Validators.required)
  });

  constructor(
    private dialogRef: MatDialogRef<AddNewMfaComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private usersSnackbarService: UsersSnackbarService,
    private identityService: IdentityService
  ) { }

  ngOnInit(): void {
    // TODO: Doing this to get the right types, can this be done better?
    this.firebaseUser = this.data.firebaseUser;
  }

  ngAfterViewInit(): void {
    this.reCaptchaObj = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible'
    });
  }


  async updateMFA(): Promise<void> {
    const reAuthCred = firebase.auth.EmailAuthProvider.credential(
      this.data.firebaseUser.email,
      this.addingNewMFAForm.value.reEnterPassword
    );

    try {
      await this.firebaseUser.reauthenticateWithCredential(reAuthCred);
      const phoneInfoOptions: firebase.auth.PhoneInfoOptions = {
        phoneNumber: this.addingNewMFAForm.value.mfaPhoneNumber,
        session: await this.firebaseUser.multiFactor.getSession()
      };
      this.localVerifyId = await this.identityService.requestMfaVerify(phoneInfoOptions, this.reCaptchaObj);
      this.addingMfaStage = 2;
      this.errMessage = '';
    } catch (err) {
      switch (err.code) {
        case 'auth/wrong-password':
          this.errMessage = err.message;
          break;
        default:
          this.errMessage = err.message;
          console.log(err);
          this.usersSnackbarService.open('Something went wrong');
          break;
      }
    }
  }

  async verifyCode(): Promise<void> {
    this.errMessage = '';
    try {
      const mfaAssertion = await this.identityService.createMfaAssertion(
        this.localVerifyId,
        this.confirmationCodeForm.value.localVerifyCode
      );
      // TODO: forbidden characters in name?
      await this.firebaseUser.multiFactor.enroll(mfaAssertion, this.addingNewMFAForm.value.mfaFactorName);
      this.dialogRef.close({ success: true });
      this.usersSnackbarService.open('New MFA has been created successfully');
    } catch (err) {
      switch (err.code) {
        case 'auth/invalid-phone-number':
          this.errMessage = 'Please enter a valid phone number';
          break;

        case 'auth/invalid-verification-code':
          this.errMessage = 'This verification code is invalid. Please try again.';
          break;
        default:
          this.usersSnackbarService.open('Something went wrong');
          console.log(err);
          this.errMessage = err.message;
          break;
      }
    }
  }

}
