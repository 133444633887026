<mat-toolbar class="toolbar" [ngStyle]="{'justify-content': printView ? 'space-around' : 'space-between'}">
  <div *ngIf="!printView && backTo == 'search'" fxLayout="row" fxLayoutAlign="start center" routerLink="/private/dashboard" class="back-container">
    <mat-icon>arrow_back</mat-icon>
    <h2>Back to Patient Search</h2>
  </div>
  <div *ngIf="!printView && backTo == 'patient-list'" fxLayout="row" fxLayoutAlign="start center" routerLink="/private/patient-list" class="back-container">
    <mat-icon>arrow_back</mat-icon>
    <h2>Back to Patient List</h2>
  </div>

  <div>
    <button *ngIf="!printView" mat-raised-button color="primary" [matMenuTriggerFor]="menu">
      <mat-icon class="material-icons-outlined">
        print
      </mat-icon>&nbsp;Print
    </button>
    <button *ngIf="!printView && patientId" mat-raised-button color="primary" class="pin-button" (click)="showPin()">
      Show pin
    </button>
    <button *ngIf="!printView" mat-raised-button color="warn" class="delete-button" (click)="askDelete()">
      <mat-icon class="material-icons-outlined">
        delete
      </mat-icon>&nbsp;Delete
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="changePrintView(true)">
        <span>This Page ({{formTypeSelectForm?.controls?.theFormType?.value}})</span>
      </button>
      <button mat-menu-item (click)="createMainPrintout()">
        <span>Must, Need, Should</span>
      </button>
      <button mat-menu-item (click)="printAll()">
        <span>All</span>
      </button>
    </mat-menu>
    <button *ngIf="printView" mat-raised-button color="primary" (click)="changePrintView(false)">
      <mat-icon class="material-icons-outlined">
        print
      </mat-icon>&nbsp;End Print View
    </button>
  </div>

  <div class="drop-down-container" *ngIf="!printView">
    <form [formGroup]="formTypeSelectForm">
      <mat-form-field appearance="outline">
        <mat-select (selectionChange)="changeFormType($event)" formControlName="theFormType">
          <mat-option *ngFor="let formType of formTypes" [value]="formType">{{formType}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</mat-toolbar>
<!-- COVER PAGE -->
<div class="cover-page" *ngIf="coverPage">
  <img src="../../../../assets/form-def-cover-page.png" (load)="changePrintView(true)">
</div>

<!-- SINGLE FORM -->
<ng-container *ngIf="viewType === 'single'">
  <div fxLayout="column" fxLayoutAlign="flex-start center" class="patient-view-container" [style.background]="selectedForm.definition.backgroundColor ? selectedForm.definition.backgroundColor : 'white'">
    <br>
    <div>
      <h1>
        {{selectedForm.definition.title}}
      </h1>
    </div>
    <br>
    <div fxLayout="row wrap" fxLayoutAlign="space-around flex-start" class="completed-form-container">
      <div *ngFor="let formGroupPayload of selectedFormArray; let x = index" class="form-section">
        <div *ngFor="let control of formGroupPayload.orderedControls; let i = index" class="form-field" [ngClass]="{'break-page': checkPageBreak(x, i)}">
          <div class="image-container" [ngStyle]="{'background-image': assignBackgroundImage(control)}"></div>
          <div class="completed-value-container">
            <h3 class="title">{{control.name}}:</h3>
            <!-- {{checkPageBreak(x, i)}} -->
            <p class="completed-value" *ngIf="formGroupPayload?.formGroup?.value[i]">{{formGroupPayload.formGroup.value[i]}}</p>
            <p class="completed-value" *ngIf="!formGroupPayload?.formGroup?.value[i]">&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- MULTIPLE FORMS -->
<ng-container *ngIf="viewType === 'multiple' && multipleFormsArray?.length">
  <ng-container *ngFor="let currentForm of multipleForms; let y = index;">
    <div fxLayout="column" fxLayoutAlign="flex-start center" class="patient-view-container multiple" [style.background]="currentForm.definition.backgroundColor ? currentForm.definition.backgroundColor : 'white'">
      <br>
      <div>
        <h1>
          {{currentForm.definition.title}}
        </h1>
      </div>
      <br>
      <div fxLayout="row wrap" fxLayoutAlign="space-around flex-start" class="completed-form-container">
        <div *ngFor="let formGroupPayload of multipleFormsArray[y]; let x = index" class="form-section">
          <div *ngFor="let control of formGroupPayload.orderedControls; let i = index" class="form-field" [ngClass]="{'break-page': multipleCheckPageBreak(multipleFormsArray[y], x, i)}">
            <div class="image-container" [ngStyle]="{'background-image': assignBackgroundImage(control)}"></div>
            <div class="completed-value-container">
              <h3 class="title">{{control.name}}: </h3>
              <!-- {{multipleCheckPageBreak(multipleFormsArray[y], x, i)}} -->
              <p class="completed-value" *ngIf="formGroupPayload?.formGroup?.value[i]">{{formGroupPayload.formGroup.value[i]}}</p>
              <p class="completed-value" *ngIf="!formGroupPayload?.formGroup?.value[i]">&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>