import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { IUser } from '../../../../authentication/interfaces/user.interface';
import { EditProfileService } from '../../../services/edit-profile.service';

@Component({
  selector: 'suvo-users-edit-names',
  templateUrl: './edit-names.component.html',
  styleUrls: ['./edit-names.component.scss']
})
export class EditNamesComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  @Input() user: IUser;

  editedUser: IUser;
  basicsForm: FormGroup;

  constructor(
    private readonly editProfileService: EditProfileService,
  ) { }

  ngOnInit(): void {
    this.editedUser = { ...this.user };
    this.initialiseBasicsForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  /*  Basics Form
  */

  onEntrySaved(): void {
    for (const key in this.basicsForm.controls) {
      this.editedUser[key] = this.basicsForm.controls[key].value;
    }
    this.editProfileService.patchUser(this.editedUser);
  }

  initialiseBasicsForm(): void {

    const nameValidators = [Validators.required, Validators.maxLength(20)];
    this.basicsForm = new FormGroup({
      firstName: new FormControl('', nameValidators),
      lastName: new FormControl('', nameValidators),

      gender: new FormControl('', [Validators.required]),
      ethnicity: new FormControl('', [Validators.required]),
      dateOfBirth: new FormControl(null, [Validators.required]),
    });

    this.setBasicsFormValues(this.editedUser);
  }

  setBasicsFormValues(user: IUser): void {
    for (const key in this.basicsForm.controls) {
      user[key] ? this.basicsForm.controls[key].setValue(user[key]) : this.basicsForm.removeControl(key);
    }
  }

}
