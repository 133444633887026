import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintViewService {
  printViewSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  updatePrintView(enabled: boolean): void {
    this.printViewSubject.next(enabled);
  }
}
