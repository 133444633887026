import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponent } from './features/public/public.component';
import { PrivateComponent } from './features/private/private.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { MyAccountComponent } from './features/private/my-account/my-account.component';
import { EmailVerificationMessageComponent } from './features/public/email-verification-message/email-verification-message/email-verification-message.component';
import { PatientSearchComponent } from './features/private/patient-search/patient-search.component';
import { PatientCompletedFormsComponent } from './features/private/patient-completed-forms/patient-completed-forms.component';
import { PatientListComponent } from './features/private/patient-list/patient-list.component';
import { WrongPlatformMessageComponent } from './features/public/wrong-platform-message/wrong-platform-message.component';
import { ClinicianListComponent } from './features/private/clinician-list/clinician-list.component';
import { AdminListComponent } from './features/private/admin-list/admin-list.component';
import { LoginComponent } from './features/public/login/login.component';
import { LoginReferrerComponent } from './features/private/login-referrer/login-referrer.component';
import { RegisterComponent } from './features/public/register/register.component';
import { EditProfileComponent } from './features/private/edit-profile/edit-profile.component';
import {
  ResetPasswordConfirmationComponent, VerifyEmergencyRemovalMfaComponent,
  OrganisationInvitationValidationComponent, ManageMfaComponent
} from '@suvo-bi-users';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['public/login']);

const routes: Routes = [
  { path: '', redirectTo: 'public/login', pathMatch: 'full' },
  {
    path: 'public', component: PublicComponent, children: [
      // { path: 'register', component: RegisterComponent },
      { path: 'register/:token/:email', component: RegisterComponent },
      { path: 'login', component: LoginComponent },
      { path: 'login/:token/:email', component: LoginComponent },
      // { path: 'verify', component: VerifyComponent },
      { path: 'forgot-password/confirmation', component: ResetPasswordConfirmationComponent },
      // { path: 'verify-email/confirmation', component: VerifyEmailConfirmationComponent },
      { path: 'verify/emergency-removal-mfa', component: VerifyEmergencyRemovalMfaComponent },
      { path: 'email-verification-message/:email', component: EmailVerificationMessageComponent },
      { path: 'wrong-platform-message', component: WrongPlatformMessageComponent },
      { path: 'invites', component: OrganisationInvitationValidationComponent }
    ]
  },
  {
    path: 'private', canActivate: [AngularFireAuthGuard], component: PrivateComponent, data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      { path: 'dashboard', component: PatientSearchComponent },
      { path: 'login-referrer', component: LoginReferrerComponent },
      // { path: 'invitation-list', component: InvitationListComponent },
      { path: 'invitation-list', redirectTo: 'login-referrer' },
      // { path: 'manage-organisations', component: ManageOrganisationsComponent },
      // { path: 'manage-subscriptions', component: ManageSubscriptionComponent },
      { path: 'patient-view', component: PatientCompletedFormsComponent },
      { path: 'patient-list', component: PatientListComponent },
      { path: 'clinician-list', component: ClinicianListComponent },
      { path: 'admin-list', component: AdminListComponent },
      {
        path: 'my-account', component: MyAccountComponent, children: [
          { path: 'edit-profile', component: EditProfileComponent },
          { path: 'manage-mfa', component: ManageMfaComponent }
        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
