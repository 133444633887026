import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { IdentityService } from '../../../../../shared/services/identity/identity.service';
import { UsersService } from '../../../../users/services/users.service';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();
  loginErr = '';
  loginHelp = '';
  loginForm!: FormGroup;

  loginStage = 1;
  mfaCode = '';

  // TODO: Correct type is "firebase.default.auth.MultiFactorResolver", but phone number doesn't always exist in the hints here?
  mfaResolver: any;

  hide = true;
  showForgotPassword = false;
  forgotPasswordRequestSent = false;
  forgotPasswordError = '';
  forgotPasswordForm: FormGroup;
  token: string;

  @Input() loginComponentOptions: {
    titleText: string,
    showRegister: boolean
  };

  firstTimeCheck = true;

  constructor(
    private usersSnackbarService: UsersSnackbarService,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private identityService: IdentityService,
    private activateRoute: ActivatedRoute,
    private usersService: UsersService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.createLoginForm();
    await this.pluckParamsFromRouting();
    this.identityService.firebaseUserSubject?.pipe(takeUntil(this.unsubscribe$)).subscribe(async (user: any) => {
      if (user?.emailVerified) {
        if (this.token) {
          this.router.navigateByUrl(`private/invitation-list?token=${this.token}`);
        } else {
          this.router.navigate(['private', 'login-referrer']);
        }
      } else if (user) {
        try {
          if (this.firstTimeCheck) {
            await user.sendEmailVerification();
          }
          this.loginErr = '';
          this.loginHelp = `A verification link has been sent to ${user.email}`;
          await this.angularFireAuth.signOut();
        } catch (err) {
          if (err.code === 'auth/too-many-requests') {
            this.loginHelp = '';
            // this.loginErr = err?.message;
            this.loginErr = 'There was an issue signing into your account.\nIf your account is not yet verified, please check for a verification email and click the link within the email.'
          } else {
            console.log(err);
            this.usersSnackbarService.open(`Something went wrong: ${err.message}`);
          }
          await this.angularFireAuth.signOut();
        }
      }
      this.firstTimeCheck = false;
    }, (err: any) => {
      console.log(err);
      this.usersSnackbarService.open(`Something went wrong: ${err.message}`);
    });

    this.createForgotPasswordForm();
  }

  pluckParamsFromRouting() {
    this.activateRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe(async (params: any) => {
      const { token } = params;
      if (token) {
        this.token = token;
        this.loginForm.addControl('token', new FormControl(this.token, Validators.required));
      }
    })
  }

  createLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl('')
    });
  }

  createForgotPasswordForm() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('')
    });
  }

  async sendForgotPassword(): Promise<void> {
    try {
      await this.angularFireAuth.sendPasswordResetEmail(this.forgotPasswordForm.value.email);
      this.forgotPasswordRequestSent = true;
      this.forgotPasswordError = '';
      await this.usersSnackbarService.open('Reset Email sent successfully.');
    } catch (err) {
      this.forgotPasswordError = err.message;
    }
  }

  toggleShowForgotPassword(): void {
    this.showForgotPassword = !this.showForgotPassword;
    this.loginErr = '';
    this.loginHelp = '';
    this.forgotPasswordError = '';
    this.forgotPasswordRequestSent = false;
  }

  async submitLogin(): Promise<void> {
    this.loginErr = '';
    this.loginHelp = '';
    try {
      const loginRes = await this.angularFireAuth.signInWithEmailAndPassword(this.loginForm.controls.email.value, this.loginForm.controls.password.value);
      if (loginRes.user?.emailVerified) {
        if (this.token) {
          this.router.navigateByUrl(`private/invitation-list?token=${this.token}`);
        } else {
          this.router.navigate(['private', 'login-referrer']);
        }
      } else {
        await loginRes.user.sendEmailVerification();
        this.loginHelp = `A verification link has been sent to ${loginRes.user.email}`;
        await this.angularFireAuth.signOut();
      }

    } catch (err) {
      console.log(err);
      switch (err.code) {
        case 'auth/multi-factor-auth-required':
          this.loginStage = 2;
          this.loginErr = '';
          this.loginHelp = '';
          this.mfaResolver = err.resolver;
          break;
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          console.log('user not found');
          this.loginHelp = '';
          this.loginErr = 'Your username or password is invalid.';
          break;
        default:
          this.usersSnackbarService.open(`Something went wrong: ${err.message}`);
          this.loginHelp = '';
          this.loginErr = err.message;
          console.log(this.loginErr);
          break;
      }
    }
  }

  goToRegister() {
    this.loginErr = '';
    this.loginHelp = '';

    this.router.navigate(['public', 'register'])
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
