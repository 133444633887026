import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  constructor(private activateRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.activateRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      if (params.mode === 'verifyEmail') {
        this.router.navigate(['public/verify-email/confirmation'], { queryParams: params });
      } else if (params.mode === 'resetPassword') {
        this.router.navigate(['public/forgot-password/confirmation'], { queryParams: params });
      } else if (params.mode === 'revertSecondFactorAddition') {
        this.router.navigate(['public/verify/emergency-removal-mfa'], { queryParams: params });
      }
      else {
        // TODO: Unit tests sometimes don't like this line?
        this.router.navigate(['public/login']);
      }
    });
  }

}
