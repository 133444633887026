<div class="form-container" *ngIf="addingMfaStage === 1">
  <h2>Add New Mfa Number</h2>
  <p>To add a new MFA number, re-enter your password and enter the chosen mobile number</p>
  <div id="recaptcha-container"></div>
  <form ngNoForm [formGroup]="addingNewMFAForm">
    <mat-form-field appearance="outline">
      <mat-label> Number Nickname </mat-label>
      <input matInput type="text" formControlName="mfaFactorName">
      <mat-error *ngIf="addingNewMFAForm.controls.mfaFactorName.hasError('required')">
        A Nickname for your number is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label> MFA Verification Number </mat-label>
      <input matInput type="text" formControlName="mfaPhoneNumber">
      <mat-error *ngIf="addingNewMFAForm.controls.mfaPhoneNumber.hasError('required')">
        MFA Verification Number is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="addingNewMFAForm.controls.mfaPhoneNumber.hasError('pattern')">
        Please enter a <strong>valid</strong> E.164 number (e.g. +447...)
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label> Password </mat-label>
      <input matInput type="password" formControlName="reEnterPassword">
      <mat-error *ngIf="addingNewMFAForm.controls.reEnterPassword.hasError('required')">
        Password is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <p class="error-msg" *ngIf="errMessage">{{errMessage}}</p>
    <button mat-raised-button [disabled]="!addingNewMFAForm.valid" (click)="updateMFA()"> Send Code </button>
  </form>
</div>

<div class="form-container" *ngIf="addingMfaStage === 2">
  <h2>Confirm Code</h2>
  <p>You will receive an SMS message on the provided mobile number shortly. Please enter the 6-digit code in the message.</p>
  <form ngNoForm [formGroup]="confirmationCodeForm">
    <mat-form-field appearance="outline">
      <mat-label> Confirmation Code </mat-label>
      <input matInput type="text" formControlName="localVerifyCode">
      <mat-error *ngIf="confirmationCodeForm.controls.localVerifyCode.hasError('required')">
        Confirmation Code is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <p class="error-msg" *ngIf="errMessage">{{errMessage}}</p>
    <button mat-raised-button [disabled]="!confirmationCodeForm.valid" (click)="verifyCode()"> Verify Code </button>
  </form>
</div>
