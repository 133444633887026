import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrivateModule } from './features/private/private.module';
import { PublicModule } from './features/public/public.module';
import { AngularFireModule } from '@angular/fire';
import { environment, firebaseConfig } from '../environments/environment';
import { NgxStripeModule } from 'ngx-stripe';
import { TENANT_ID } from '@angular/fire/auth';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { TableComponent } from './shared/table/components/table.component';

@NgModule({
  declarations: [
    AppComponent,
    // TableComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PrivateModule,
    PublicModule,
    AngularFireModule.initializeApp(firebaseConfig),
    NgxStripeModule.forRoot(environment.stripeKey)
  ],
  providers: [
    { provide: TENANT_ID, useValue: environment.targetTenant },
    { provide: LOCALE_ID, useValue: "en-GB" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
