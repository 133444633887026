import { Inject, Injectable } from '@angular/core';
import { CommonModel } from '../../models/common/common-model';
import { ApiService } from '../api/api.service';

@Injectable()
export class CommonService<T extends CommonModel<T>> {

  constructor(@Inject("SUVO_CORE_CREATE_T") createT: { new(): T }, protected apiService: ApiService, @Inject("SUVO_CORE_BASE_URL") public baseUrl: string, @Inject("SUVO_CORE_ROUTE") public route: string) { }

  async getRouteUrl(): Promise<string> {
    return `${this.baseUrl}${this.route}`;
  }

  async getOne(route: string, params?: any): Promise<T> {
    return this.apiService.get(await this.getRouteUrl() + route);
  }

  async get(route: string, params?: any): Promise<Array<T> | T> {
    return this.apiService.get(await this.getRouteUrl() + route);
  }

  async post(route: string, data?: any): Promise<T> {
    return this.apiService.post(await this.getRouteUrl() + route, data);
  }

  async put(route: string, data?: any): Promise<T> {
    return this.apiService.put(await this.getRouteUrl() + route, data);
  }

  async patch(route: string, data?: any): Promise<T> {
    return this.apiService.patch(await this.getRouteUrl() + route, data);
  }

  async delete(route: string, data?: any): Promise<T> {
    return this.apiService.delete(await this.getRouteUrl() + route, data);
  }

}
