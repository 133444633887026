import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularMaterialModule } from './angular-material.module';
import { environment } from '../../../environments/environment';
import { SuvoUsersClientLibModule } from '@suvo-bi-users';
import { CommonComponentsModule } from '@suvo-bi-core';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    SuvoUsersClientLibModule.forRoot(environment),
    CommonComponentsModule
  ],
  exports: [
    HttpClientModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    SuvoUsersClientLibModule,
    CommonComponentsModule
  ],
  providers: []
})
export class SharedModule { }
