import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { TenantRoleService, PrintViewService } from '@suvo-bi-users';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateComponent implements OnInit, OnDestroy {
  params: Params;
  showMyAccountRoutes = false;
  currentRole: string;

  printViewEnabledSub: Subscription;
  printViewEnabled = false;

  constructor(
    private router: Router,
    private tenantRoleService: TenantRoleService,
    private printViewService: PrintViewService
  ) {
    this.params = this.router.events.subscribe(
      async (event) => {
        if (event instanceof NavigationEnd) {
          if (event.urlAfterRedirects.includes('my-account')) {
            this.showMyAccountRoutes = true;
          } else {
            this.showMyAccountRoutes = false;
            this.tenantRoleService.currentRoleSubject.subscribe((roleObj) => {
              if (roleObj?.role) {
                this.currentRole = roleObj.role;
              }
            });
          }
        }
      }
    );
  }

  ngOnInit(): void {
    this.printViewEnabledSub = this.printViewService.printViewSubject.subscribe((enabled) => {
      this.printViewEnabled = enabled;
    });
  }

  ngOnDestroy(): void {
    if (this.printViewEnabledSub) {
      this.printViewEnabledSub.unsubscribe();
    }
  }

}
