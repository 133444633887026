import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TableService } from '../../../shared/table/services/table.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends TableService {

  // TODO: ApiService does not accept 'route' as an argument.
  public route: string = "admin";

  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient, "admin")
  }

  async getAllAdmins(): Promise<any> {
    return super.get(`${environment.usersServiceApi}${this.route}`);
  }

}
