<div class="container padding" fxLayout="row">

  <div fxFlex="1 1" fxLayout="row wrap" fxLayoutAlign="flex-start center">
    <p class="entry-label" fxFlex="0 0 96px">{{ label || 'No Label' }}</p>

    <div fxFlex="1 1 " fxLayout="row" fxLayoutAlign="flex-start center">

      <!-- Edit Input-->
      <mat-form-field fxFlex="0 1" class="entry-form-field" *ngIf="editMode; else notEditEntry">

        <input matInput #inputElement [formControl]="entryControl">

        <mat-error *ngIf="entryControl.hasError('required')">
          * Required.
        </mat-error>

        <mat-error *ngIf="entryControl.hasError('maxlength')">
          Too long.
        </mat-error>


      </mat-form-field>
      <!-- Not Editting No Input-->
      <ng-template #notEditEntry>
        <p class="entry">{{ entryControl?.value }}</p>
      </ng-template>

    </div>
  </div>


  <!-- Edit Buttons -->
  <ng-container *ngIf="!forbidEdit">
    <div class="entry-buttons-container" *ngIf="editMode" fxLayout="row" fxLayoutAlign="flex-end center">
      <button mat-icon-button class="edit-entry" (click)="finish(true)" [disabled]="entryControl.invalid">
        <mat-icon>check</mat-icon>
      </button>
      <button mat-icon-button class="edit-entry" (click)="finish(false)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <!-- Not Editting, One button to enter edit -->
    <div class="entry-buttons-container" *ngIf="!editMode" fxLayout="row" fxLayoutAlign="flex-end center">
      <button *ngIf="entryControl" mat-icon-button class="edit-entry" (click)="edit()" matTooltip="Edit">
        <mat-icon>edit</mat-icon>
      </button>
      <button *ngIf="!entryControl" mat-icon-button class="edit-entry" matTooltip="No entry control.">
        <mat-icon>warning</mat-icon>
      </button>
    </div>
  </ng-container>
</div>