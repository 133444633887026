<h4 mat-dialog-title>{{ data?.title || 'No title?' }}</h4>

<ng-container [ngSwitch]="verifyStatus">
  <div mat-dialog-content *ngSwitchCase="'enter-password'">
    <suvo-users-password-field *ngIf="passwordFormControl" [localFormControl]="passwordFormControl"></suvo-users-password-field>
  </div>

  <div mat-dialog-content *ngSwitchCase="'verify-mfa'">
    <lib-verify-mfa [mfaResolver]="mfaResolver" [showTitle]="false" (mfaSuccess)="mfaSuccess()"></lib-verify-mfa>
  </div>

  <ng-container *ngSwitchCase="'loading'">
    <suvo-core-loading-spinner message="Sending..."></suvo-core-loading-spinner>
  </ng-container>

  <ng-container *ngSwitchCase="'error'">
    <div fxLayout="column" fxLayoutAlign="center center">
      <suvo-core-error-message title="Failed to remove!" [message]="errorMessage"></suvo-core-error-message>
      <p>Please try again!</p>
    </div>
  </ng-container>
</ng-container>

<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="">Cancel</button>
  <button mat-flat-button *ngIf="verifyStatus === 'enter-password'" (click)="submitPassword()" color="primary" [disabled]="passwordFormControl.invalid">Submit</button>
</div>