import { CommonModel } from '@suvo-bi-core';

export class UserModel extends CommonModel<UserModel> {
  _id!: string;
  firstName!: string;
  lastName!: string;
  email!: string;
  firebaseUserId!: string;
  gender!: string;
  ethnicity!: string;
  dateOfBirth?: string;
  tenantId!: string;
  constructor() {
    super(UserModel);
  }
}
