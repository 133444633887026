import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ApiService } from '@suvo-bi-core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TableService extends ApiService {

  constructor(protected httpClient: HttpClient, protected route: string) {
    super(httpClient);
  }

  getPaginated(filters: any, sort: any, pagination: PageEvent): Promise<any> {
    const tableOptions: any = {};

    if (filters) {
      tableOptions.filters = JSON.stringify(filters);
    }

    if (sort) {
      tableOptions.sort = JSON.stringify(sort);
    }

    if (pagination) {
      tableOptions.pagination = JSON.stringify(pagination);
    }

    return this.httpClient.get(`${environment.usersServiceApi}${this.route}`, {
      params: tableOptions
    }).toPromise();
  }
}
