<div class="manage-sub-container">
  <button mat-icon-button [matMenuTriggerFor]="menu" class="more-button" *ngIf="!loading && !enteringPaymentInfo && isOrganisationAdmin && selectedOrgCustomerSubs?.length">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item class="cancel-sub-button" (click)="cancelSubscription()">
      Cancel Subscription
    </button>
  </mat-menu>
  <div class="manage-sub-wrapper" *ngIf="selectedOrganisation?.value">
    <div class="manage-header">
      <h4>{{selectedOrganisation.value.organisationName}} Subscription</h4>
    </div>

    <mat-form-field *ngIf="navType === 'multiple'">
      <mat-label> Pick your Organisation</mat-label>
      <mat-select placeholder="Placeholder" [disabled]="enteringPaymentInfo" [formControl]="selectedOrganisation" (selectionChange)="selectOrganisation()">
        <mat-option *ngFor="let organisation of organisationsList" [value]="organisation">
          {{organisation.organisationName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="loading" class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>

    <ng-container *ngIf="!loading && !enteringPaymentInfo && isOrganisationAdmin">
      <div class="products-container">
        <div class="product" *ngFor="let product of productList">
          <h3>{{product.name}}</h3>
          <p>{{product.description}}</p>
          <!-- <p class="product-price-line">First Seat:<br><strong>{{(product.price.tiers[0].unit_amount / 100) | currency:product.price.currency.toUpperCase():'symbol' : '1.0-0'}} per seat, per year</strong></p>
          <p class="product-price-line">Additional Seats:<br><strong>{{(product.price.tiers[1].unit_amount / 100) | currency:product.price.currency.toUpperCase():'symbol' : '1.0-0'}} per seat, per year</strong></p> -->
          <ng-container *ngFor="let tier of product.price.tiers; let i = index">
            <p *ngIf="i === 0" class="product-price-line">
              <ng-container *ngIf="tier.up_to === 1">First Seat:</ng-container>
              <ng-container *ngIf="tier.up_to > 1">Seats 1 - {{ tier.up_to }}:</ng-container>
              <br>
              <strong>{{(tier.unit_amount / 100) | currency:product.price.currency.toUpperCase():'symbol' : '1.0-0'}} per seat</strong>
            </p>
            <p *ngIf="i > 0 && (i+1) !== product.price.tiers.length" class="product-price-line">
              <ng-container *ngIf="(tier.up_to - product.price.tiers[i-1].up_to) > 1">Seats {{ product.price.tiers[i-1].up_to }} - {{ tier.up_to }}:</ng-container>
              <ng-container *ngIf="(tier.up_to - product.price.tiers[i-1].up_to) === 1">Seat {{ tier.up_to }}:</ng-container>
              <br>
              <strong>{{(tier.unit_amount / 100) | currency:product.price.currency.toUpperCase():'symbol' : '1.0-0'}} per seat</strong>
            </p>
            <p *ngIf="(i+1) === product.price.tiers.length" class="product-price-line">
              <ng-container>Seats {{ product.price.tiers[i-1].up_to }}+:</ng-container>
              <br>
              <strong>{{(tier.unit_amount / 100) | currency:product.price.currency.toUpperCase():'symbol' : '1.0-0'}} per seat</strong>
            </p>


          </ng-container>

          <!-- TODO: Hardcoded for £. Dynamic pricing pipe somehow? -->
          <!-- TODO: Placeholder, we need to have these prices factor in their member count, and project the price -->
          <!-- <button *ngIf="!selectedOrgCustomerSubs.length" mat-raised-button color="primary" (click)="createSubscription(product.price)">Subscribe [ORG_MEMBER_COUNT] users for £[PRICE] per month</button>
        <button *ngIf="selectedOrgCustomerSubs.length && (selectedOrgCustomerSubs[0].items.data[0].price.id !== product.price.id)" mat-raised-button color="primary" (click)="updateSubscription(product.price)">Change and Subscribe [ORG_MEMBER_COUNT] users for £[PRICE] per month</button>
        <button *ngIf="(selectedOrgCustomerSubs.length) && (selectedOrgCustomerSubs[0].items.data[0].price.id === product.price.id)" mat-raised-button color="accent" (click)="cancelSubscription()">Cancel Plan</button> -->

          <mat-checkbox *ngIf="product.suvoChecked !== null && product.suvoChecked !== undefined" [(ngModel)]="product.suvoChecked" (ngModelChange)="reCalculatePrice(); checkIfDirty();" color="primary">Subscribe to plan</mat-checkbox>
        </div>
      </div>
      <div>
        <br>
        <hr>
        <br>
        <div>
          <div class="seats-row-container">
            <div>
              <h4>Seats</h4>
              <p class="seats-subtext">Add more seats to invite more users to your organisation, and take <br> advantage of multi-seat discounts!</p>
            </div>
            <div class="seats-container">
              <p>Seats</p>
              <div>
                <mat-form-field appearance="outline" class="seats-field">
                  <input matInput type="number" [formControl]="seats">
                </mat-form-field>
              </div>
            </div>
          </div>
          <br>
          <hr>
          <br>

          <div>
            <ng-container *ngFor="let product of productList">
              <div *ngIf="product.suvoChecked">
                <p class="price-line">
                  {{product.name}} x {{seats.value}} = {{(calcProductTiersPrice(product.price.tiers, seats.value) / 100) | currency:product.price.currency.toUpperCase()}}
                </p>
              </div>
            </ng-container>
            <br>
            <div *ngIf="productList.length">
              <p class="price-line"><strong>TOTAL: </strong>{{previewPrice / 100 | currency:productList[0].price.currency.toUpperCase()}} / year</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <p *ngIf="!isOrganisationAdmin">You must be the admin of the selected organisation to change the manage subscriptions.</p>
  </div>

  <ng-container *ngIf="!loading && !enteringPaymentInfo && isOrganisationAdmin">
    <div class="stripe-elements-container" *ngIf="!selectedOrgCustomerSubs?.length && selectedOrgCustomer?.metadata.usedTrial === 'true'">
      <button button mat-raised-button color="primary" class="confirm-payment" [disabled]="!productSelectedCheck()" (click)="createSubscription()">
        Create New Subscription
      </button>
    </div>

    <div class="stripe-elements-container" *ngIf="!selectedOrgCustomerSubs?.length && selectedOrgCustomer?.metadata.usedTrial === 'false'">
      <button button mat-raised-button color="primary" class="confirm-payment" [disabled]="!productSelectedCheck()" (click)="createSubscription()">
        Start your 7-Day Free Trial
      </button>
    </div>

    <div class="stripe-elements-container" *ngIf="selectedOrgCustomerSubs?.length">
      <button button mat-raised-button color="primary" class="confirm-payment" [disabled]="!productSelectedCheck() || !dirtyCheckObj.isDirty" (click)="updateSubscription()">
        Update Subscription
      </button>
    </div>

  </ng-container>

  <!-- <div class="sub-options-container" *ngIf="!loading && !enteringPaymentInfo && isOrganisationAdmin" [ngStyle]="{'justify-content':selectedOrgCustomerSubs?.length ? 'space-between' : 'center'}">
    <div class="stripe-elements-container" *ngIf="selectedOrgCustomerSubs?.length">
      <button button mat-raised-button color="warn" class="confirm-payment" (click)="cancelSubscription()">
        Cancel Subscription
      </button>
    </div>

    <div class="stripe-elements-container">
      <button button mat-raised-button color="primary" class="confirm-payment" (click)="gotoCustomerPortal()">
        Change Billing Details
      </button>
    </div>
  </div> -->

  <div class="stripe-elements-container stripe-elements-card-container" *ngIf="clientSecret && enteringPaymentInfo && isOrganisationAdmin">
    <p class="payment-subheading">Please enter your card details to confirm your subscription.</p>
    <ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
    <button button mat-raised-button color="primary" class="confirm-payment" (click)="confirmCardPayment()">
      Confirm Payment
    </button>
  </div>
</div>