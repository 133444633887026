<!-- TODO: Offloaded to private component. Not needed anymore? -->
<!-- <mat-sidenav-container autosize>
  <mat-sidenav #drawer fixedInViewport="false" [opened]="true" [mode]="'side'">
    <mat-nav-list>
      <div>
        <a mat-list-item [routerLinkActive]="['is-active']" routerLink="/private/my-account/edit-profile">Edit Profile</a>
      </div>
      <div>
        <a mat-list-item [routerLinkActive]="['is-active']" routerLink="/private/my-account/manage-mfa">Manage MFA</a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content> -->
    <router-outlet></router-outlet>
  <!-- </mat-sidenav-content>
</mat-sidenav-container> -->