import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IdentityService } from '../../../../../shared/services/identity/identity.service';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';
import firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  selector: 'lib-verify-mfa',
  templateUrl: './verify-mfa.component.html',
  styleUrls: ['./verify-mfa.component.scss']
})
export class VerifyMfaComponent implements OnInit, AfterViewInit {

  selectedMfaForm: FormGroup | any;
  selectedMfaOption: any;
  mfaCodeForm!: FormGroup;
  localVerifyId: string | any;

  @Input() mfaResolver: any;
  @Input() showTitle = true;
  @Output() mfaSuccess = new EventEmitter<void>();

  verifyStage = 1;
  verifyError!: string;

  localRecaptchaObj!: firebase.auth.RecaptchaVerifier;

  constructor(
    private usersSnackbarService: UsersSnackbarService,
    private identityService: IdentityService
  ) { }

  ngOnInit(): void {
    this.selectedMfaForm = new FormGroup({
      selectedMfaOption: new FormControl('', Validators.required)
    });

    this.mfaCodeForm = new FormGroup({
      mfaCode: new FormControl('', Validators.required)
    });
  }

  ngAfterViewInit(): void {
    this.localRecaptchaObj = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible'
    });
  }

  async chooseMfa(): Promise<void> {
    // Ensure user has selected a "phone" factor
    console.log(this.selectedMfaForm.value);
    if (this.selectedMfaForm.value.selectedMfaOption.factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
      const phoneInfoOptions = {
        multiFactorHint: this.selectedMfaForm.value.selectedMfaOption,
        session: this.mfaResolver.session
      };

      try {
        this.localVerifyId = await this.identityService.requestMfaVerify(phoneInfoOptions, this.localRecaptchaObj);
        console.log('LOCAL ID', this.localVerifyId);
        this.verifyStage = 2;
        this.verifyError = '';
      } catch (err) {
        console.log(err);
        this.usersSnackbarService.open(`Something went wrong with your phone number, please try again. Caused by: ${err.message} `);
      }
    }
  }

  async submitMfa(): Promise<void> {
    try {
      const mfaAssertion = await this.identityService.createMfaAssertion(this.localVerifyId, this.mfaCodeForm.value.mfaCode);
      await this.mfaResolver.resolveSignIn(mfaAssertion);
      this.verifyError = '';
      this.mfaSuccess.emit();
    } catch (err) {
      switch (err.code) {
        case 'auth/invalid-verification-code':
          this.verifyError = 'This verification code is invalid. Please try again.';
          break;
        default:
          this.usersSnackbarService.open('Something went wrong with your multi factor authentication');
          break;
      }
    }
  }

}

