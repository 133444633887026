import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { OrganisationRoleService, TenantRoleService } from '@suvo-bi-users';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Hospital Passport Clinician Portal';

  constructor(
    private tenantRoleService: TenantRoleService,
    private organisationRoleService: OrganisationRoleService,
    private angularFireAuth: AngularFireAuth
  ) {
    this.angularFireAuth.authState.subscribe(async (user) => {
      if (user) {
        await this.tenantRoleService.loadRole();

        // TODO: only supporting one org + role for the time being
        const orgRoles = await this.organisationRoleService.getRoles();
        if (Array.isArray(orgRoles) && orgRoles?.length) {
          this.organisationRoleService.setCurrentRole(orgRoles[0]);
        }
      }
    });
  }

}
