<div class="manage-org-container">
  <div class="manage-org-wrapper">
    <div class="manage-header">
      <h2>Manage Organisations</h2>
      <button mat-raised-button color="primary" (click)="createOrganisation()">Create Organisation</button>
    </div>

    <mat-form-field>
      <mat-label> Pick your Organisation</mat-label>
      <mat-select placeholder="Placeholder" [formControl]="selectedOrganisation" (selectionChange)="selectOrganisation()">
        <mat-option *ngFor="let organisation of organisationsList" [value]="organisation">
          {{organisation.organisationName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label> Organisation Name </mat-label>
      <input matInput [formControl]="selectedOrganisationName">
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="updateOrganisation()">Update Organisation</button>
    <br>
    <button mat-raised-button color="primary" [disabled]="!selectedOrganisation.value?.organisationId" (click)="openInvitationDialog()">Invite User to Selected Organisation</button>
  </div>
</div>