import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditProfileService } from '../../../../edit-profile/services/edit-profile.service';

@Component({
  selector: 'lib-verify-mfa-dialog',
  templateUrl: './verify-mfa-dialog.component.html',
  styleUrls: ['./verify-mfa-dialog.component.scss']
})
export class VerifyMfaDialogComponent {

  verifyStatus: 'enter-password' | 'verify-mfa' | 'loading' | 'error' = 'enter-password';

  passwordFormControl: FormControl = new FormControl('', Validators.required);
  mfaResolver;
  errorMessage = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<VerifyMfaDialogComponent>,
    private readonly profileManagementService: EditProfileService,
  ) { }

  async submitPassword(): Promise<void> {
    if (this.passwordFormControl.valid) {
      this.verifyStatus = 'loading';
      try {
        await this.profileManagementService.reAuthenticateLocalUser(this.passwordFormControl.value);
        this.mfaSuccess();
      } catch (err) {
        console.log(err);
        switch (err.code) {
          case 'auth/multi-factor-auth-required':
            this.mfaResolver = err.resolver;
            this.verifyStatus = 'verify-mfa';
            break;

          case 'auth/wrong-password':
            this.errorMessage = err.message;
            this.verifyStatus = 'enter-password';
            setTimeout(() => {
              this.passwordFormControl.setErrors({ 'invalid-pass': true });
            }, 0);
            break;

          default:
            this.verifyStatus = 'error';
            this.errorMessage = err.message;
            break;
        }
      }
    }
  }

  mfaSuccess(): void {
    this.dialogRef.close({
      success: true
    });
  }

}
