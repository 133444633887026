import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditProfileService } from '../../../services/edit-profile.service';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';

@Component({
  selector: 'suvo-users-remove-mfa-dialog',
  templateUrl: './remove-mfa-dialog.component.html',
  styleUrls: ['./remove-mfa-dialog.component.scss']
})
export class RemoveMfaDialogComponent implements OnInit {

  removeStatus: 'enter-password' | 'loading' | 'success' | 'verify-mfa' | 'error' = 'enter-password';
  errorMessage: string;

  passwordFormControl: FormControl = new FormControl('', Validators.required);
  mfaResolver: any;

  constructor(
    private readonly dialogRef: MatDialogRef<RemoveMfaDialogComponent>,
    private readonly profileManagementService: EditProfileService,
    private readonly usersSnackbarService: UsersSnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  async submitPassword(): Promise<void> {
    if (this.passwordFormControl.valid && this.data.mfaOption) {
      this.removeStatus = 'loading';
      try {
        await this.profileManagementService.reAuthenticateLocalUser(this.passwordFormControl.value);
      } catch (err) {
        console.log(err);
        switch (err.code) {
          case 'auth/multi-factor-auth-required':
            this.mfaResolver = err.resolver;
            this.removeStatus = 'verify-mfa';
            break;

          case 'auth/wrong-password':
            this.errorMessage = err.message;
            this.removeStatus = 'enter-password';
            break;

          default:
            this.removeStatus = 'error';
            this.errorMessage = err.message;
            break;
        }
      }
    }
  }

  async mfaSuccess(): Promise<void> {
    try {
      await this.profileManagementService.removeMfaVerification(this.data.mfaOption);
      this.dialogRef.close({ success: true });
      this.usersSnackbarService.open(`${this.data.mfaOption.displayName} option removed.`);
    } catch (err) {
      console.log(err);
    }
  }

}
