import { Component } from '@angular/core';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'suvo-core-success-message',
  templateUrl: '../icon-message/icon-message.component.html',
  styleUrls: ['../icon-message/icon-message.component.scss']
})
export class SuccessMessageComponent extends ErrorMessageComponent {
  icon = 'check'
  constructor() {
    super();
  }
}
