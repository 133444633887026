<h4 mat-dialog-title>Create Organisation</h4>
<mat-dialog-content>
  <div class="new-org-container">
    <form [formGroup]="newOrganisationForm">
      <mat-form-field appearance="outline">
        <mat-label> Organisation Name </mat-label>
        <input matInput formControlName="organisationName">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> First Branch Name </mat-label>
        <input matInput formControlName="firstBranchName">
      </mat-form-field>
    </form>
    <button mat-raised-button color="primary" (click)="createOrganisation()">Create Organisation</button>
  </div>

</mat-dialog-content>