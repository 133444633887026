<form class="form-container" [formGroup]="reAuthPasswordForm" *ngIf="removeStage === 1">
  <h2>Remove Mfa Number</h2>
  <p>To remove this MFA method, re-enter your password.</p>
  <mat-form-field appearance="outline">
    <mat-label> Password </mat-label>
    <input matInput [type]="hide ? 'password' : 'text'" type="password" formControlName="reAuthPassword">
    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <mat-icon> {{hide ? 'visibility_off' : 'visibility'}} </mat-icon>
    </button>
  </mat-form-field>
  <p class="error-msg" *ngIf="errMessage">{{errMessage}}</p>
  <button mat-raised-button [disabled]="!reAuthPasswordForm.valid" (click)="submit()"> Confirm Password </button>
</form>

<lib-verify-mfa *ngIf="removeStage === 2 && mfaResolver" [mfaResolver]="mfaResolver" (mfaSuccess)="mfaSuccess()"></lib-verify-mfa>
