export interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  firebaseUserId: string;
  gender: string;
  ethnicity: string;
  dateOfBirth?: string;
  tenantId: string;
}
