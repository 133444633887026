import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageSubscriptionComponent } from '../components/manage-subscription/manage-subscription.component';
import { SubscriptionService } from '../services/subscription.service';
import { SharedModule } from '../../../shared/modules/shared/shared.module';
import { NgxStripeModule } from 'ngx-stripe';

@NgModule({
  declarations: [
    ManageSubscriptionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxStripeModule
  ],
  providers: [
    SubscriptionService
  ],
  exports: [
    ManageSubscriptionComponent
  ]
})
export class SubscriptionModule { }
