import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Route, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { IBreadcrumbLink } from '../../interfaces/breadcrumb-link.interface';
import { BreadcrumbsService } from '../../services/breadcrumbs.service';

@Component({
  selector: 'suvo-core-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  links: IBreadcrumbLink[] = [];
  unsubscribe$ = new Subject<boolean>();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly breadcrumbsService: BreadcrumbsService,
  ) {
  }

  buildBreadcrumbs(): void {
    this.links = this.breadcrumbsService.buildBreadcrumb(this.activatedRoute.root);
  }

  ngOnInit(): void {

    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.buildBreadcrumbs();
    })

    this.breadcrumbsService.nameSwapsModified.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.buildBreadcrumbs();
    });

    this.buildBreadcrumbs();

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}
