import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { OrganisationInvitationDialogComponent, OrganisationRoleModel, OrganisationRoleService } from '@suvo-bi-users';
import { TableComponent } from '../../../shared/table/components/table.component';
import { ClinicianService } from '../../services/clinician/clinician.service';

@Component({
  selector: 'app-clinician-list',
  templateUrl: './clinician-list.component.html',
  styleUrls: ['./clinician-list.component.scss']
})
export class ClinicianListComponent extends TableComponent<any> {

  clinicians = [];
  displayedColumns: string[] = ['firstName', 'lastName', 'email'];
  dataSource: MatTableDataSource<any>;
  localOrgRole: OrganisationRoleModel;

  constructor(
    private clinicianService: ClinicianService,
    private organisationRoleService: OrganisationRoleService,
    private dialog: MatDialog,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: FormBuilder
  ) {
    super(activatedRoute, clinicianService, formBuilder);
    this.organisationRoleService.currentOrganisationRoleSubject.subscribe((orgRole) => {
      this.localOrgRole = orgRole;
    });
  }

  clinicianClicked(): void {
    // TODO: What to do here? EDIT: Only deleting...
  }

  inviteClinician(): void {
    if (!this.localOrgRole?.organisationId) {
      throw new Error('You are not part of an organisation.');
    }

    this.dialog.open(OrganisationInvitationDialogComponent, {
      width: '500px',
      data: {
        organisationId: this.localOrgRole?.organisationId,
        customTitle: 'Invite a clinician to Hospital Passport',
        restrictedDomain: 'nhs.net'
      }
    });
  }
}
