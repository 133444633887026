import { CommonModel } from '@suvo-bi-core';

export class CarModel extends CommonModel<CarModel> {
  branchId: string;
  model: string;
  brand: string;
  cost: string;
  constructor() {
    super(CarModel);
  }
}
