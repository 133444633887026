import { Inject, Injectable } from '@angular/core';
import { CommonModel, CommonService, ApiService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '../../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { OrganisationRoleService } from '../../../organisation-role/services/organisation-role.service';

@Injectable()
export class CommonOrganisationService<T extends CommonModel<T>> extends CommonService<T> {
  constructor(
    @Inject('SUVO_USERS_CREATET') createT: new () => T,
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService,
    @Inject('SUVO_USERS_ROUTE') route: string,
    protected organisationRoleService: OrganisationRoleService
  ) {
    super(createT, apiService, settings.dataServiceApi, route);
  }

  async getRouteUrl(): Promise<string> {
    const organisationRole = await this.organisationRoleService.getCurrentRole();
    if (!organisationRole) {
      throw new Error('No active organisation');
    }
    return `${this.baseUrl}organisations/${organisationRole.organisationId}/${this.route}`;
  }
}
