import { Injectable } from '@angular/core';
import { ApiService, CommonService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '@suvo-bi-users';
import { CompletedFormModel } from '../models/completed-form.model';

@Injectable({
  providedIn: 'root'
})
export class CompletedFormService extends CommonService<CompletedFormModel> {

  constructor(
    apiService: ApiService,
    private settings: SuvoUsersClientLibSettingsService
  ) {
    super(CompletedFormModel, apiService, settings.dataServiceApi, 'form-definitions/');
  }

  async upsertForFormDefinition(completedForm: any, formDefinitionId: string): Promise<CompletedFormModel> {
    return this.post(`${formDefinitionId}/completed-forms/`, completedForm);
  }

  async getPatientCompletedForm(targetFormDefinitionId: string, userInfo: any): Promise<any> {
    return (await this.post(`${targetFormDefinitionId}/completed-forms/latest/clinician-view`,
      userInfo
    ));
  }

  async getPatientCompletedFormAsAdmin(targetFormDefinitionId: string, patientId): Promise<any> {
    return (await this.get(`${targetFormDefinitionId}/admin/completed-forms/admin-view/${patientId}`
    ));
  }


  // TODO: Type this properly
  async convertToCompletedForm(formArray): Promise<{ completedFormValues: any[] }> {
    // const completedFormValues:  = [];
    const completedForm = {
      completedFormValues: []
    };
    for (let i = 0; i < formArray.length; i++) {
      const formObj = formArray[i];

      for (let x = 0; x < formObj.orderedControls.length; x++) {
        completedForm.completedFormValues.push({
          formControlId: formObj.orderedControls[x]._id,
          value: formObj.formGroup.controls[`${x}`].value
        });
      }
    }
    return completedForm;
  }
}
