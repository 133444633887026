import { Inject, Injectable } from '@angular/core';
import { CommonModel, ApiService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '../../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { BranchRoleService } from '../../../branch-role/services/branch-role.service';
import { OrganisationRoleService } from '../../../organisation-role/services/organisation-role.service';
import { CommonOrganisationService } from '../../../organisation/services/common-organisation/common-organisation.service';

Injectable()
export class CommonBranchService<T extends CommonModel<T>> extends CommonOrganisationService<T> {
  constructor(
    @Inject('SUVO_USERS_CREATET') createT: new () => T,
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService,
    @Inject('SUVO_USERS_ROUTE') route: string,
    organisationRoleService: OrganisationRoleService,
    protected branchRoleService: BranchRoleService
  ) {
    super(createT, apiService, settings, route, organisationRoleService);
  }

  async getRouteUrl(): Promise<string> {
    const organisationRole = await this.organisationRoleService.getCurrentRole();
    if (!organisationRole) {
      throw new Error('No active organisation');
    }

    const branchRole = await this.branchRoleService.getCurrentRole();
    if (!branchRole) {
      throw new Error('No active branch');
    }

    return `${this.baseUrl}organisations/${organisationRole.organisationId}/branches/${branchRole.branchId}/${this.route}`;
  }
}
