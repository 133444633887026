<h4 mat-dialog-title>{{ data?.title || 'No title?' }}</h4>
<div mat-dialog-content>


  <div id="recaptcha-container"></div>
  <mat-stepper #stepper linear>

    <mat-step [editable]="false" completed="false" label="Details">

      <!-- Details Form -->
      <form *ngIf="form" [formGroup]="form" fxLayout="column">

        <!-- Nickname -->
        <mat-form-field fxFlex="0 1" appearance="outline">
          <mat-label>Nickname</mat-label>
          <input matInput formControlName="displayName">

          <mat-error *ngIf="form.get('displayName').hasError('required')">
            * Required.
          </mat-error>

          <mat-error *ngIf="form.get('displayName').hasError('maxlength')">
            Too long.
          </mat-error>
        </mat-form-field>


        <!-- Phone Number -->
        <mat-form-field fxFlex="0 1" appearance="outline">
          <mat-label>Phone Number</mat-label>
          <input matInput formControlName="phoneNumber">

          <mat-error *ngIf="form.get('phoneNumber').hasError('required')">
            * Required.
          </mat-error>

          <mat-error *ngIf="form.get('phoneNumber').hasError('pattern')">
            Not a valid phone number.
          </mat-error>
        </mat-form-field>

        <!-- Password Field-->
        <suvo-users-password-field [localFormControl]="form.get('password')"></suvo-users-password-field>

      </form>

      <div mat-dialog-actions>
        <button mat-flat-button [mat-dialog-close]="">Close</button>
        <button mat-flat-button (click)="submitDetails()" color="primary" [disabled]="form.invalid">Submit</button>
      </div>
    </mat-step>

    <mat-step label="Verify">
      <ng-container [ngSwitch]="verifyStatus">

        <!-- Receiving -->
        <ng-container *ngSwitchCase="'receiving-code'">
          <suvo-core-loading-spinner message="Sending Code"></suvo-core-loading-spinner>
        </ng-container>

        <!-- Submitting -->
        <ng-container *ngSwitchCase="'submitting-code'">
          <suvo-core-loading-spinner message="Submitting Code"></suvo-core-loading-spinner>
        </ng-container>

        <!-- Error -->
        <ng-container *ngSwitchCase="'error'">
          <suvo-core-error-message title="Something went wrong!" [message]="errorMessage"></suvo-core-error-message>
        </ng-container>

        <!-- Success, All Done! -->
        <ng-container *ngSwitchCase="'success'">
          <suvo-core-success-message title="Success" message="This option has been added!"></suvo-core-success-message>
        </ng-container>

        <!-- Enter Code -->
        <ng-container *ngSwitchCase="'enter-code'">

          <div fxLayout="column stretch">
            <h4>Confirmation Code</h4>
            <p>You will receive an SMS message on this number shortly. Enter the sent code.</p>

            <!-- Code Form -->
            <mat-form-field fxFlex="0 1" appearance="outline">

              <mat-label>Confirmation Code</mat-label>

              <input matInput [formControl]="codeFormControl">

              <mat-error *ngIf="codeFormControl.hasError('required')">
                * Required.
              </mat-error>

              <mat-error *ngIf="codeFormControl.hasError('pattern')">
                The code should be a 6 digit number.
              </mat-error>

              <mat-error *ngIf="codeFormControl.hasError('invalid-code')">
                Invalid code.
              </mat-error>
            </mat-form-field>
            <div mat-dialog-actions>
              <button mat-flat-button [mat-dialog-close]="">Cancel</button>
              <button mat-flat-button (click)="submitCode()" color="primary" [disabled]="codeFormControl.invalid">Submit</button>
            </div>
          </div>


        </ng-container>


      </ng-container>

    </mat-step>
  </mat-stepper>


</div>