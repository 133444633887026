import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';


@Component({
  selector: 'app-email-verification-message',
  templateUrl: './email-verification-message.component.html',
  styleUrls: ['./email-verification-message.component.scss']
})
export class EmailVerificationMessageComponent implements OnInit {
  email: string;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(take(1)).subscribe(param => {
      this.email = param.email;
    });
  }

}
