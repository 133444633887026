import {IForms} from "../interfaces/forms.interface";

export const EmptyForms: IForms = {
    must: {
      definition: null,
      answers: null
    },
    need: {
      definition: null,
      answers: null
    },
    should: {
      definition: null,
      answers: null
    },
    epilepsy: {
      definition: null,
      answers: null
    },
    mental: {
      definition: null,
      answers: null
    },
    autism: {
      definition: null,
      answers: null
    },
    pain: {
      definition: null,
      answers: null
    },
    adjustments: {
      definition: null,
      answers: null
    }
  };