<div>

  <ng-container *ngIf="mfaOptions?.length; else noMfaOptions">

    <!-- <div class="padding">
      <ng-container *ngIf="mfaOptions.length == 1; else multipleOptions">
        <p>Signing into your account will also require you to use this second step.</p>
      </ng-container>
      <ng-template #multipleOptions>
        <p>You may use any of these options in the second step to signing in.</p>
      </ng-template>
    </div>
    <mat-divider></mat-divider> -->

    <ng-container *ngFor="let option of mfaOptions">
      <div class="padding" fxLayout="row" fxLayoutAlign="flex-start center">
        <div fxFlex="0 0 96px">
          <p class="entry-label">{{ option.displayName }}</p>
        </div>
        <div fxFlex="1 1" fxLayout="row" fxLayoutAlign="flex-start center">
          <p class="entry">{{ option.phoneNumber }}</p>
        </div>

        <!-- <button mat-icon-button class="edit-entry" matTooltip="Unimplemented.">
          <mat-icon>edit</mat-icon>
        </button> -->

        <button mat-icon-button class="edit-entry" (click)="removeMfaOption(option)">
          <mat-icon>close</mat-icon>
        </button>

      </div>

      <mat-divider>
      </mat-divider>
    </ng-container>

  </ng-container>
  <ng-template #noMfaOptions>
    <div class="padding" fxLayoutAlign="center center">
      <p style="text-align: center;">Improve your security by adding a second step to signing in!</p>
    </div>
  </ng-template>

  <div class="padding" fxLayoutAlign="center center">
    <button mat-flat-button class="edit-entry" (click)="openAddMfaDialog()">
      <mat-icon>add</mat-icon> Add
    </button>
  </div>

</div>