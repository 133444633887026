import { Injectable } from '@angular/core';
import { OrganisationModel } from '../../models/organisation-model';
import { SuvoUsersClientLibSettingsService } from '../../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { CommonService, ApiService } from '@suvo-bi-core';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService extends CommonService<OrganisationModel> {

  constructor(apiService: ApiService, private settings: SuvoUsersClientLibSettingsService) {
    super(OrganisationModel, apiService, settings.usersServiceApi, 'organisation/');
  }
}
