import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


import firebase from 'firebase/app';
import 'firebase/auth';
import { IdentityService } from '../../../shared/services/identity/identity.service';
import { UsersService } from '../../users/services/users.service';
import { UserModel } from '../../users/models/user-model';
import { IUser } from '../../authentication/interfaces/user.interface';


@Injectable({
  providedIn: 'root'
})
export class EditProfileService {

  constructor(
    private identityService: IdentityService,
    private usersService: UsersService,
  ) { }

  getUserSubject(): Subject<IUser> {
    return this.usersService.userSubject;
  }

  triggerUserChanges() {
    return this.usersService.loadUser();
  }

  async patchUser(user: IUser): Promise<IUser> {
    await this.usersService.patch('me/profile', user);
    return await this.usersService.loadUser();
  }

  getFirebaseUserSubject(): BehaviorSubject<firebase.User> {
    return this.identityService.firebaseUserSubject;
  }

  async startMfaVerification(phoneNumber: string, reCaptchaObj: firebase.auth.RecaptchaVerifier): Promise<string> {
    try {
      const firebaseUser = this.identityService.firebaseUserSubject.getValue() as firebase.User;
      const phoneInfoOptions: firebase.auth.PhoneInfoOptions = {
        phoneNumber,
        session: await firebaseUser.multiFactor.getSession(),
      };

      const localVerifyId = await this.identityService.requestMfaVerify(phoneInfoOptions, reCaptchaObj);

      return localVerifyId;

    } catch (error) {
      throw error;
    }
  }

  async completeMfaVerification(verifyId: string, confirmationCode: string, mfaDisplayName: string): Promise<string> {
    try {
      const mfaAssertion = this.identityService.createMfaAssertion(
        verifyId,
        confirmationCode,
      );

      const firebaseUser = this.identityService.firebaseUserSubject.getValue() as firebase.User;

      await firebaseUser.multiFactor.enroll(mfaAssertion, mfaDisplayName);

      return 'success';
    } catch (error) {
      throw error;
    }

  }

  async reAuthenticateLocalUser(password: string): Promise<firebase.auth.UserCredential> {
    const firebaseUser = this.identityService.firebaseUserSubject.getValue() as firebase.User;
    const reAuthCredential = firebase.auth.EmailAuthProvider.credential(
      firebaseUser.email, password
    );

    try {
      return await firebaseUser.reauthenticateWithCredential(reAuthCredential);
    } catch (error) {
      throw error;
    }
  }

  async removeMfaVerification(mfaOption): Promise<string | Error> {
    const firebaseUser = this.identityService.firebaseUserSubject.getValue() as firebase.User;
    try {
      await firebaseUser.multiFactor.unenroll(mfaOption);
      return 'success';
    } catch (error) {
      throw error;
    }

  }

}
