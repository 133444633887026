import { CommonModel } from '@suvo-bi-core';

export class OrganisationRoleModel extends CommonModel<OrganisationRoleModel> {
  firebaseUserId: string;
  role: string;
  organisationId: string;
  organisationName: string;
  tenantId: string;
  constructor() {
    super(OrganisationRoleModel);
  }
}
