import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { PrintViewService } from '@suvo-bi-users';
import { FormDefinitionService } from '../../../shared/form-definition/services/form-definition.service';
import {PatientService} from '../../services/patient/patient.service';
import {PinDialogComponent} from '../patient-list/dialogs/pin-dialog/pin-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDeletePatientDialogComponent} from './dialogs/confirm-delete-patient-dialog/confirm-delete-patient-dialog.component';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-patient-completed-forms',
  templateUrl: './patient-completed-forms.component.html',
  styleUrls: ['./patient-completed-forms.component.scss']
})
export class PatientCompletedFormsComponent {
  completedPatientForms;
  formTypes = ['MUST', 'NEED', 'SHOULD', 'EPILEPSY', 'MENTAL', 'AUTISM', 'PAIN', 'ADJUSTMENTS'];
  formTypeSelectForm: FormGroup;
  selectedForm;
  selectedFormArray;
  printView = false;

  viewType: 'single' | 'multiple' = 'single';
  coverPage = false;
  multipleForms = [];
  multipleFormsArray = [];

  backTo;
  patientId;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formDefinitionService: FormDefinitionService,
    private printViewService: PrintViewService,
    private patientService: PatientService,
    private dialog: MatDialog
  ) {
    this.completedPatientForms = this.router.getCurrentNavigation()?.extras?.state?.forms;
    if (!this.completedPatientForms) {
      this.router.navigate(['../dashboard'], {
        relativeTo: this.activatedRoute
      });
    }

    debugger;

    this.backTo = this.router.getCurrentNavigation()?.extras?.state?.backTo;
    this.patientId = this.router.getCurrentNavigation()?.extras?.state?.patientId;

    this.formTypeSelectForm = new FormGroup({
      theFormType: new FormControl(''),
    });
    this.formTypeSelectForm.controls.theFormType.setValue(this.formTypes[0]);
    this.selectedForm = this.completedPatientForms.must;
    this.selectedFormArray = this.formDefinitionService.convertToReadOnlyFormArray(
      this.completedPatientForms.must.definition,
      this.completedPatientForms.must.answers
    );
    console.log(this.selectedFormArray);

  }

  setupPrintSettings(): void {
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.media = 'print';
    style.appendChild(document.createTextNode('@page { size: A4;  margin: 0in; }'));
    head.appendChild(style);
  }

  changePrintView(x: boolean): void {
    this.printView = x;
    this.printViewService.updatePrintView(this.printView);
    setTimeout(() => {
      if (this.printView) {
        this.setupPrintSettings();
        window.print();
      }
      if (!this.printView) {
        this.viewType = 'single';
        this.multipleFormsArray = [];
        this.multipleForms = [];
        this.coverPage = false;
      }
    }, 0);
  }

  changeFormType(event: MatSelectChange): void {
    this.selectedForm = this.completedPatientForms[event.value.toLowerCase()];
    this.selectedFormArray = this.formDefinitionService.convertToReadOnlyFormArray(
      this.completedPatientForms[event.value.toLowerCase()].definition,
      this.completedPatientForms[event.value.toLowerCase()].answers
    );
  }

  assignBackgroundImage(control): string {
    if (control.imageName) {
      return `url('../../../../assets/form-def-images/${control.imageName}')`;
    } else {
      return null;
    }
  }

  createMainPrintout(): void {
    const types = ['must', 'need', 'should'];

    this.multipleFormsArray = [];
    this.multipleForms = [];
    for (const formType of types) {
      this.multipleForms.push(this.completedPatientForms[formType]);
      this.multipleFormsArray.push(this.formDefinitionService.convertToReadOnlyFormArray(
        this.completedPatientForms[formType].definition,
        this.completedPatientForms[formType].answers
      ));
    }
    this.coverPage = true;
    this.viewType = 'multiple';
    // We wait for cover page to load before attempting print view
  }

  printAll(): void {
    // this.printView = true;
    // this.printViewService.updatePrintView(this.printView);

    this.multipleFormsArray = [];
    this.multipleForms = [];
    for (const formType of this.formTypes) {
      this.multipleForms.push(this.completedPatientForms[formType.toLowerCase()]);
      this.multipleFormsArray.push(this.formDefinitionService.convertToReadOnlyFormArray(
        this.completedPatientForms[formType.toLowerCase()].definition,
        this.completedPatientForms[formType.toLowerCase()].answers
      ));
    }
    this.coverPage = true;
    this.viewType = 'multiple';

    // Not needed, we listen for the cover page load
    // this.changePrintView(true);
  }

  // TODO: Merge 2 below functions together using optionals
  checkPageBreak(sectionIndex: number, fieldIndex: number): boolean {
    let count = 0;
    for (const [x, section] of this.selectedFormArray.entries()) {
      for (const [i, field] of section.orderedControls.entries()) {
        count++;
        if (x === sectionIndex && i === fieldIndex) {
          if (!(count % 9)) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    return true;
  }

  multipleCheckPageBreak(formArray: any, sectionIndex: number, fieldIndex: number): boolean {
    let count = 0;
    for (const [x, section] of formArray.entries()) {
      for (const [i, field] of section.orderedControls.entries()) {
        count++;
        if (x === sectionIndex && i === fieldIndex) {
          if (!(count % 8)) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    return true;
  }

  async showPin(){
    const pinObj = await this.patientService.getPatientPin(this.patientId);
    this.dialog.open(PinDialogComponent, {
      width: '250px',
      data: { pin: pinObj.pin },
    });
  }

  async askDelete(){
    this.dialog.open(ConfirmDeletePatientDialogComponent, {
      width: '250px',
      data: { patientId: this.patientId },
    }).afterClosed().pipe(take(1)).subscribe((res:any) => {
      debugger;
      if(res.deleted){
        this.router.navigate(['../dashboard'], {
          relativeTo: this.activatedRoute
        });
      }
    });
  }
}
