<mat-toolbar class="invitation-toolbar">
  <button mat-raised-button color="primary" [disabled]="!selectedOrganisationRole.value?.organisationId" (click)="openInvitationDialog()"> Invite</button>
  <button mat-raised-button color="primary" [routerLink]="['../','manage-organisations']">Manage Organisations</button>
</mat-toolbar>

<mat-toolbar>
  <mat-toolbar-row class="organisation-toolbar">
    <mat-form-field>
      <mat-label> Pick your Organisation</mat-label>
      <mat-select placeholder="Placeholder" [formControl]="selectedOrganisationRole" (selectionChange)="selectOrganisation()">
        <mat-option *ngFor="let organisation of organisationsList" [value]="organisation">
          {{organisation.organisationName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-toolbar-row>

  <mat-toolbar-row *ngIf="selectedOrganisationRole.value" class="branch-toolbar">
    <mat-form-field>
      <mat-label> Pick your Branch</mat-label>
      <mat-select placeholder="Placeholder" [formControl]="selectedBranchRole" (selectionChange)="selectBranch()">
        <mat-option *ngFor="let branch of branchRoleList" [value]="branch">
          {{branch.branchName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-toolbar-row>
</mat-toolbar>

<div class="dashboard-content-container">
  <div class="action-buttons-container">
    <button mat-stroked-button> Cars</button>
    <button mat-stroked-button (click)="selectOrganisation()"> Animals</button>
  </div>
  <mat-spinner *ngIf="loading"></mat-spinner>
  <div *ngIf="!loading">
    <h2> Animals </h2>
    <div *ngFor="let animal of animals" class="message-from-organisation-roles">
      Name: {{ animal.name}}
    </div>
    <h2> Cars </h2>
    <div *ngFor="let car of cars" class="message-from-organisation-roles">
      Model: {{ car.model}}
      Brand: {{ car.brand}}
    </div>
  </div>
  <div class="message-content">
    {{ dataServiceMsg }}
  </div>
</div>
