import { CommonModel } from '@suvo-bi-core';

export class BranchRoleModel extends CommonModel<BranchRoleModel> {
  firebaseUserId: string;
  role: string;
  branchId: string;
  branchName: string;
  organisationId: string;
  tenantId: string;
  constructor() {
    super(BranchRoleModel);
  }
}
