<div fxLayout="row" fxLayoutAlign="flex-start center" class="breadcrumb-container">

  <div *ngFor=" let link of links; let i=index; let last=last" fxLayoutAlign="center center">

    <ng-container *ngIf="!last; else lastLabel">
      <a matRipple class="breadcrumb-button" [routerLink]="link.route">{{ link.displayName }}</a>
      <mat-icon *ngIf="!last">chevron_right</mat-icon>
    </ng-container>

    <ng-template #lastLabel>
      <a class="breadcrumb-button">{{ link.displayName }}</a>
    </ng-template>

  </div>


</div>