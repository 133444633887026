import { Injectable } from '@angular/core';
import { ApiService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { ProductService } from '../../product/services/product.service';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private settings: SuvoUsersClientLibSettingsService,
    private apiService: ApiService,
    private productService: ProductService
  ) { }

  getOrganisationCustomer(organisationId: string): Promise<any> {
    return this.apiService.get(`${this.settings.subscriptionServiceApi}subscription/customer/${organisationId}`);
  }

  getProductList(organisationId: string): Promise<any> {
    return this.apiService.get(`${this.settings.subscriptionServiceApi}subscription/products/${organisationId}`);
  }

  getActiveCustomerSubscriptions(organisationId: string): Promise<any> {
    return this.apiService.get(`${this.settings.subscriptionServiceApi}subscription/active-subscriptions/${organisationId}`);
  }

  async createSubscription(priceIds: string[], organisationId: string, seats: number): Promise<any> {
    const newSub = await this.apiService.post(`${this.settings.subscriptionServiceApi}subscription/create-subscription/${organisationId}`,
      { priceIds, organisationId, seats });
    await this.productService.fetchAllOwned();
    return newSub;
  }

  async updateSubscription(priceIds: string[], organisationId: string, subscriptionId: string, seats: number): Promise<any> {
    const updatedSub = await this.apiService.put(
      `${this.settings.subscriptionServiceApi}subscription/update-subscription/${organisationId}`,
      { priceIds, organisationId, subscriptionId, seats });
    await this.productService.fetchAllOwned();
    return updatedSub;
  }

  async cancelSubscription(subscriptionId: string, organisationId: string): Promise<any> {
    const cancelledSub = await this.apiService.post(`${this.settings.subscriptionServiceApi}subscription/cancel-subscription/${organisationId}`, { subscriptionId });
    await this.productService.fetchAllOwned();
    return cancelledSub;
  }

  createCustomerPortalSession(organisationId: string, redirectType: 'personal' | 'organisation' = 'personal'): Promise<any> {
    return this.apiService.get(`${this.settings.subscriptionServiceApi}subscription/customer-portal/${organisationId}?redirect_type=${redirectType}`);
  }
}
