import { CommonModel } from '@suvo-bi-core';
import { PublicUserModel } from '../../users-public/models/public-user-model';

// TODO: This is WET code compared to the OrganisationRoleModel. How can we DRY it?
export class PublicOrganisationRoleModel extends CommonModel<PublicOrganisationRoleModel> {
  firebaseUserId: string;
  role: string;
  organisationId: string;
  organisationName: string;
  tenantId: string;
  publicUserProfile: PublicUserModel;
  constructor() {
    super(PublicOrganisationRoleModel);
  }
}
