<lib-dashboard-header *ngIf="!printViewEnabled"></lib-dashboard-header>
<mat-sidenav-container autosize>
  <mat-sidenav #drawer fixedInViewport="false" [opened]="true" [mode]="'side'" *ngIf="!printViewEnabled">
    <mat-nav-list>
      <ng-container *ngIf="!showMyAccountRoutes">
        <div *ngIf="(currentRole === 'CLINICIAN') || (currentRole === 'ADMIN')">
          <a mat-list-item [routerLinkActive]="['is-active']" routerLink="/private/dashboard">Patient Search</a>
        </div>
        <div *ngIf="currentRole === 'ADMIN'">
          <a mat-list-item [routerLinkActive]="['is-active']" routerLink="/private/patient-list">Patient List</a>
        </div>
        <div *ngIf="currentRole === 'ADMIN'">
          <a mat-list-item [routerLinkActive]="['is-active']" routerLink="/private/clinician-list">Clinician List</a>
        </div>
        <div *ngIf="currentRole === 'ADMIN'">
          <a mat-list-item [routerLinkActive]="['is-active']" routerLink="/private/admin-list">Admin List</a>
        </div>
      </ng-container>

      <ng-container *ngIf="showMyAccountRoutes">
        <div>
          <a mat-list-item [routerLinkActive]="['is-active']" routerLink="/private/my-account/edit-profile">Edit Profile</a>
        </div>
        <div>
          <a mat-list-item [routerLinkActive]="['is-active']" routerLink="/private/my-account/manage-mfa">Manage MFA</a>
        </div>
      </ng-container>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>