<h4 mat-dialog-title *ngIf="!data.customTitle">Share this organisation with other people</h4>
<h4 mat-dialog-title *ngIf="data.customTitle">{{ data.customTitle }}</h4>
<div mat-dialog-content>

  <form [formGroup]="invitationForm">
    <mat-form-field>
      <mat-label> Please enter an email address</mat-label>
      <input matInput formControlName="email" required>
      <mat-error *ngIf="invitationForm?.controls.email.hasError('email') && !invitationForm?.controls.email.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="invitationForm?.controls.email.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="invitationForm?.controls.email.hasError('pattern') && !invitationForm?.controls.email.hasError('email')">
        Email must belong to {{this.data?.restrictedDomain}}
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" [mat-dialog-close]="data.organisationId" [disabled]="!invitationForm.valid" (click)="sendInvitation()">Done</button>
</div>
