import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../services/data-api/data-api.service';
import { DataModel } from '../../../shared/models/data-model';
import { FormControl } from '@angular/forms';
import { AnimalService } from '../../services/animal/animal.service';
import { CarService } from '../../services/cars/cars.service';
import { MatDialog } from '@angular/material/dialog';
import { OrganisationRoleService, BranchRoleService, BranchRoleModel, OrganisationInvitationDialogComponent } from '@suvo-bi-users';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  selectedOrganisationRole = new FormControl();
  defaultOrganisation: any;
  organisationsList: [];
  branchRoleList = [];
  selectedBranchRole = new FormControl();
  branchList: [];
  loading = false;

  dataServiceMsg: string;
  animals: [];
  cars: [];
  constructor(
    private dataApiService: DataApiService,
    private organisationRoleService: OrganisationRoleService,
    private branchRoleService: BranchRoleService,
    private animalService: AnimalService,
    private carService: CarService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getData();
    this.getMyOrganisations();
  }

  getData(): void {
    this.dataApiService.get('').then((res: DataModel[]) => {
      this.dataServiceMsg = res[0].data;
    });
  }

  getMyOrganisations(): void {
    this.organisationRoleService.get('').then((res: any) => {
      this.organisationsList = res;
      this.defaultOrganisation = res[0];
      this.selectedOrganisationRole.setValue(this.defaultOrganisation);
    });
  }

  getSelectedOrgBranches(): void {
    this.branchRoleService.get('').then((res: BranchRoleModel[]) => {
      this.branchRoleList = res.filter(branchRole => branchRole.organisationId === this.selectedOrganisationRole.value.organisationId);
    }).catch((err) => {
      console.log(err);
    });
  }

  selectOrganisation(): void {
    // Pass in extra stuff for selected role
    this.organisationRoleService.setCurrentRole(this.selectedOrganisationRole.value);
    this.getOrganisationsData();
    this.getSelectedOrgBranches();
  }

  selectBranch(): void {
    // Set current role on branchService
    this.branchRoleService.setCurrentRole(this.selectedBranchRole.value);
    this.getBranchData();
  }

  getOrganisationsData(): void {
    this.loading = true;
    this.animalService.get('').then((res: any) => {
      this.animals = res;
      this.loading = false;
    }).catch((err) => {
      console.log(err);
    });
  }

  getBranchData(): void {
    this.carService.get('').then((res: any) => {
      this.branchList = res;
      this.cars = res;
    }).catch((err) => {
      console.log(err);
    });
  }

  openInvitationDialog(): void {
    const dialogRef = this.dialog.open(OrganisationInvitationDialogComponent, {
      width: '500px',
      data: { organisationId: this.selectedOrganisationRole.value?.organisationId }
    });
  }
}
