import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'email-verification-message',
  templateUrl: './email-verification-message.component.html',
  styleUrls: ['./email-verification-message.component.scss']
})
export class EmailVerificationMessageComponent implements OnInit {
  @Input() email?: string;
  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void { }

}
