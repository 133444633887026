import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'suvo-core-route-toggle-group',
  templateUrl: './route-toggle-group.component.html',
  styleUrls: ['./route-toggle-group.component.scss']
})
export class RouteToggleGroupComponent implements OnInit {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  @Input() showIcons: boolean = false;

  links: { displayName?: string, displayIcon?: string, route?: string }[];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.links = [];

    this.activatedRoute.routeConfig.children.forEach((child) => {
      if (child.data?.breadcrumb) {
        this.links.push({
          displayName: child.data.breadcrumb,
          displayIcon: this.showIcons && child.data.breadcrumbIcon,
          route: child.path,
        })
      }
    })

  }

}
