import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TableComponent } from '../../../shared/table/components/table.component';
import { AdminService } from '../../services/admin/admin.service';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss']
})
export class AdminListComponent extends TableComponent<any> {

  admins = [];
  displayedColumns: string[] = ['firstName', 'lastName', 'email'];
  dataSource: MatTableDataSource<any>;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected adminService: AdminService,
    protected formBuilder: FormBuilder
  ) {
    super(activatedRoute, adminService, formBuilder);
  }

  adminClicked(): void {
    // TODO: What to do here?
    console.log('TODO');
  }

}
