import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, flatMap, takeUntil } from 'rxjs/operators';
import { TableService } from '../services/table.service';

@Component({
  selector: 'emt-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(LoadingSpinnerComponent, { static: false }) loadingSpinner: LoadingSpinnerComponent;
  filtersSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sortSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  paginationSubject: BehaviorSubject<PageEvent> = new BehaviorSubject<PageEvent>(null);
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  data$: Observable<any> = of();
  isLoading: boolean;
  sessionStorageRouteKey: string;
  sessionStorageKeys: any = {};
  pageOptions: any = {
    pageSizes: [5, 10, 25, 100],
    defaultPageSize: 10
  };
  displayedColumns: string[] = [];
  searchBox: FormControl;
  searchBoxSub: Subscription;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected tableService: TableService,
    protected formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.searchBox = new FormControl('');
    this.searchBoxSub = this.searchBox.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(500))
      .subscribe(newValue => { this.nextFiltersSubject({ searchAll: newValue }); });
    this.setStorageKeys(this.activatedRoute.snapshot.url.toString());
    this.getTableArguments();
    this.data$ = this.getPaginated();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged()).subscribe((pageEvent: PageEvent) => {
        if (pageEvent) {

          if (this.paginationSubject.value.pageSize !== pageEvent.pageSize) {
            pageEvent.pageIndex = 0;
          }
          this.paginationSubject.next(pageEvent);
        }
      });
    }

    if (this.sortSubject.value && this.sort) {
      this.sort.sort({ id: this.sortSubject.value.field, start: this.sortSubject.value.dir } as MatSortable);
    }
    if (this.sort) {
      this.sort.sortChange.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged()).subscribe((sort: Sort) => {
        if (sort) {
          if (!sort.direction) {
            this.sortSubject.next(null);
          } else {
            this.sortSubject.next({ field: sort.active, dir: sort.direction });
          }

          const pagination = this.paginationSubject.value;
          pagination.pageIndex = 0;
          this.paginationSubject.next(pagination);
        }
      });
    }
  }

  setStorageKeys(uniquePageKey: string): void {
    this.sessionStorageKeys.filters = `filters-${uniquePageKey}`;
    this.sessionStorageKeys.pagination = `pagination-${uniquePageKey}`;
    this.sessionStorageKeys.sort = `sort-${uniquePageKey}`;
  }

  setTableArguments(filters, sort, pagination): void {
    sessionStorage.setItem(this.sessionStorageKeys.filters, JSON.stringify(filters));
    sessionStorage.setItem(this.sessionStorageKeys.sort, JSON.stringify(sort));
    sessionStorage.setItem(this.sessionStorageKeys.pagination, JSON.stringify(pagination));
  }

  getTableArguments(): void {
    const filters = sessionStorage.getItem(this.sessionStorageKeys.filters);
    if (filters) {
      const parsedFilters = JSON.parse(filters);
      if (parsedFilters?.searchAll) {
        this.searchBox.setValue(parsedFilters.searchAll);
      }
      this.filtersSubject.next(parsedFilters);
    }

    const sort = sessionStorage.getItem(this.sessionStorageKeys.sort);
    if (sort) {
      const parsedSort = JSON.parse(sort);
      this.sortSubject.next(parsedSort);
    }

    const pagination = sessionStorage.getItem(this.sessionStorageKeys.pagination);
    if (pagination) {
      this.paginationSubject.next(JSON.parse(pagination));
    } else {
      this.paginationSubject.next({ pageSize: this.pageOptions.defaultPageSize, pageIndex: 0, length: 0 });
    }
  }

  getPaginated(): Observable<MatTableDataSource<T>> {
    return combineLatest([
      this.filtersSubject.asObservable().pipe(takeUntil(this.unsubscribe$), distinctUntilChanged()),
      this.sortSubject.asObservable(),
      this.paginationSubject.asObservable()
    ]).pipe(flatMap(async ([filters, sort, pagination]) => {
      this.isLoading = true;
      this.setTableArguments(filters, sort, pagination);
      const result: any = await this.tableService.getPaginated(filters, sort, pagination);
      const dataSource = new MatTableDataSource<T>(result.data);

      if (this.paginator) {
        this.paginator.length = result.count;
        this.paginator.pageSize = pagination.pageSize;
        this.paginator.pageIndex = pagination.pageIndex;
      }
      this.isLoading = false;
      return dataSource;
    }), takeUntil(this.unsubscribe$));
  }

  refreshTable(): void {
    this.paginationSubject.next(Object.assign({}, this.paginationSubject.value));
  }

  nextFiltersSubject(value: any): void {
    this.filtersSubject.next(value);
    const pagination = this.paginationSubject.value;
    pagination.pageIndex = 0;
    this.paginationSubject.next(pagination);
  }

  nextSortSubject(event): void {
    if (!event.direction) {
      this.sortSubject.next(null);
    } else {
      this.sortSubject.next({ field: event.active, dir: event.direction });
    }

    const pagination = this.paginationSubject.value;
    pagination.pageIndex = 0;
    this.paginationSubject.next(pagination);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
