import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Injectable()
export class ApiService {

  constructor(private http: HttpClient) { }

  get(route: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`${route}`).pipe(take(1)).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  post(route: string, data?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${route}`, data).pipe(take(1)).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  put(route: string, data?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(`${route}`, data).pipe(take(1)).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  patch(route: string, data?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.patch(`${route}`, data).pipe(take(1)).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  delete(route: string, data?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.delete(`${route}`, data).pipe(take(1)).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }
}
