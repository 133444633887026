import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'lib-suvo-core-client-lib',
  template: ``,
  styles: []
})
export class SuvoCoreClientLibComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
