import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditNamesComponent } from './components/exported/edit-names/edit-names.component';
import { CardEntryComponent } from './components/common/card-entry/card-entry.component';
import { SharedModule } from '../../shared/modules/shared/shared.module';
import { EditMfaComponent } from './components/exported/edit-mfa/edit-mfa.component';
import { AddMfaDialogComponent } from './components/dialogs/add-mfa-dialog/add-mfa-dialog.component';
import { RemoveMfaDialogComponent } from './components/dialogs/remove-mfa-dialog/remove-mfa-dialog.component';
import { AuthenticationModule } from '../authentication/modules/authentication/authentication.module';
import { EmailChangedDialogComponent } from './components/dialogs/email-changed-dialog/email-changed-dialog.component';

@NgModule({
  declarations: [
    AddMfaDialogComponent,
    RemoveMfaDialogComponent,
    EditNamesComponent,
    EditMfaComponent,
    CardEntryComponent,
    EmailChangedDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthenticationModule
  ],
  exports: [
    EditNamesComponent,
    EditMfaComponent,
    CardEntryComponent
  ]
})
export class EditProfileModule { }
