import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService, CommonService } from '@suvo-bi-core';
import { SuvoUsersClientLibSettingsService } from '@suvo-bi-users';
import { ICompletedForm } from '../interfaces/completed-form.interface';
import { IFormControl } from '../interfaces/form-control.interface';
import { IFormDefinition } from '../interfaces/form-definition.interface';
import { FormDefinitionModel } from '../models/form-definition.model';

@Injectable({
  providedIn: 'root'
})
export class FormDefinitionService extends CommonService<FormDefinitionModel> {

  constructor(apiService: ApiService, private settings: SuvoUsersClientLibSettingsService) {
    super(FormDefinitionModel, apiService, settings.dataServiceApi, 'form-definitions/');
  }

  // TODO: enforce type enums
  async getByType(type: string): Promise<IFormDefinition> {
    return this.getOne(type);
  }

  convertToReadOnlyFormArray(formDefinition: IFormDefinition, existingValues: ICompletedForm): any {

    const convertedFormDefinition = [];

    // const ngFormArray = new FormArray([]);
    formDefinition.formStructure.sections.forEach((section: any) => {
      section.formGroups.forEach((formGroup: any) => {
        const ngFormGroup: FormGroup = new FormGroup({});
        const orderedControls = [];
        formGroup.formControlIds.forEach((formControlId: string, index: number) => {
          const formControl = this.findFormControl(formControlId, formDefinition.formControls);
          if (!formControl) {
            throw new Error(`FORM DEFINITION: Could not load form control with id ${formControlId}. `)
          }
          // TODO: Optimize?
          let existingControlValue = null;
          existingValues?.completedFormValues?.forEach((completedValue) => {
            if (completedValue.formControlId === formControlId) {
              existingControlValue = completedValue.value;
            }
          });
          orderedControls.push(formControl);
          const fc = new FormControl();
          if (existingControlValue) {
            fc.setValue(existingControlValue);
          }
          ngFormGroup.addControl(index.toString(), fc)
        });
        convertedFormDefinition.push({
          formGroup: ngFormGroup,
          orderedControls
        });
      });
    });
    return convertedFormDefinition;
  }

  private findFormControl(formControlId: string, formControls: IFormControl[]): IFormControl {
    return formControls.find((formControl: IFormControl) => {
      return formControl._id === formControlId;
    });
  }
}
