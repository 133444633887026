import { Component, Input } from '@angular/core';

@Component({
  selector: 'suvo-core-icon-message',
  templateUrl: './icon-message.component.html',
  styleUrls: ['./icon-message.component.scss']
})
export class IconMessageComponent {

  @Input()
  icon: string;

  @Input()
  title: string;

  @Input()
  message: string;

  constructor() { }

}
