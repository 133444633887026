import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { IdentityService } from '../../../../shared/services/identity/identity.service';
import { UsersService } from '../../../users/services/users.service';
import { IUser } from '../../../authentication/interfaces/user.interface';
import firebase from 'firebase/app';
import 'firebase/auth';
import { ethnicities } from '../../../../shared/constants/ethnicity-list';
import { UsersSnackbarService } from '../../../../shared/services/users-snackbar/users-snackbar.service';

@Component({
  selector: 'lib-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {
  profileForm: FormGroup;
  localFirebaseUser: firebase.User;
  localUser: IUser;
  editedUser: IUser;
  unsubscribe$ = new Subject<boolean>();
  genders = ['Male', 'Female', 'Other'];
  ethnicityList = ethnicities;

  @Input() hideFields: string[];

  constructor(
    private identityService: IdentityService,
    private usersSnackbarService: UsersSnackbarService,
    private usersService: UsersService,
    public router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    this.usersService.userSubject.pipe(takeUntil(this.unsubscribe$),
      catchError(firstErr =>
        of(this.usersSnackbarService.open(`Something went wrong ${firstErr}`))
        // TODO: Rewrote to stop the compiler complaining. I think this works the same way?
        // tap(secondErr => this.matSnackBarService
        // .openSnackBar(`Something went wrong ${secondErr}`, 'failureCssSnackBar'))
      ))
      .subscribe((data: IUser) => {
        this.localUser = data;
        this.initialiseProfileForm();
      });


    this.identityService.firebaseUserSubject?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (user) => {
        if (user?.uid) {
          this.localFirebaseUser = user;
        }
      });
  }

  applyProfileChanges(): void {
    const dirtyFields: any = {};
    Object.keys(this.profileForm.controls).forEach((control) => {
      if(this.profileForm.controls[control].dirty) {
        dirtyFields[control] = (this.profileForm.controls[control].value).trim();
      }
    })
    this.usersService.patch('me/profile', dirtyFields).then(res => {
      this.usersSnackbarService.open('User has been updated');
      this.usersService.loadUser();
    }).catch(err => console.log(err));
  }


  initialiseProfileForm(): void {
    this.profileForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      gender: new FormControl(''),
      ethnicity: new FormControl(''),
      dateOfBirth: new FormControl(null, [Validators.required]),
    });

    this.profileForm.controls.firstName.setValue(this.localUser?.firstName);
    this.profileForm.controls.lastName.setValue(this.localUser?.lastName);
    this.profileForm.controls.gender.setValue(this.localUser?.gender);
    this.profileForm.controls.ethnicity.setValue(this.localUser?.ethnicity);
    this.profileForm.controls.dateOfBirth.setValue(this.localUser?.dateOfBirth);

    if (this.hideFields?.length) {
      this.hideFields.forEach((formControlName) => {
        this.profileForm.removeControl(formControlName);
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
