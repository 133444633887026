export class CommonModel<T> {

  _id!: string;

  constructor(private createT: new () => T) { }

  clone(): T {
    const newObject = new this.createT();
    Object.assign(newObject as T, this);
    return newObject;
  }

  populate(object?: T): void {
    Object.assign(this, object);
  }
}
