import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/modules/shared/shared.module';
import { ProductService } from '../services/product.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    ProductService
  ]
})
export class ProductLibModule { }
