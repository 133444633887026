import { Injectable } from '@angular/core';
import { ApiService } from '@suvo-bi-core';
import { BehaviorSubject } from 'rxjs';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { ProductDashboardModel } from '../models/product-for-dashboard.model';


@Injectable({
  providedIn: 'root'
})
export class ProductService {
  ownedProductsList: BehaviorSubject<ProductDashboardModel[]> = new BehaviorSubject<ProductDashboardModel[]>(null);

  constructor(
    private settings: SuvoUsersClientLibSettingsService,
    private apiService: ApiService
  ) { }

  async fetchAllOwned(): Promise<ProductDashboardModel[]> {
    try {
      this.ownedProductsList.next(await this.apiService.get(`${this.settings.subscriptionServiceApi}product/owned`));
      return this.ownedProductsList.value;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
