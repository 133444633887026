import { Injectable } from '@angular/core';
import { OrganisationRoleModel } from '../models/organisation-role.model';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { BehaviorSubject } from 'rxjs';
import { ProductService } from '../../product/services/product.service';
import { CommonService, ApiService } from '@suvo-bi-core';

@Injectable({
  providedIn: 'root'
})
export class OrganisationRoleService extends CommonService<OrganisationRoleModel> {
  currentOrganisationRoleSubject: BehaviorSubject<OrganisationRoleModel> = new BehaviorSubject<OrganisationRoleModel>(null);
  allOrganisationRolesSubject: BehaviorSubject<OrganisationRoleModel[]> = new BehaviorSubject<OrganisationRoleModel[]>(null);

  constructor(apiService: ApiService, private settings: SuvoUsersClientLibSettingsService, private productService: ProductService) {
    super(OrganisationRoleModel, apiService, settings.usersServiceApi, 'me/organisations/roles/');
  }

  async getRole(roleId: string) {
    return this.get(roleId);
  }

  async getRoles(): Promise<OrganisationRoleModel[] | OrganisationRoleModel> {
    const roles = await this.get('') as OrganisationRoleModel[];
    this.allOrganisationRolesSubject.next(roles);
    return roles;
  }

  async setCurrentRole(orgRole: OrganisationRoleModel): Promise<void> {
    this.currentOrganisationRoleSubject.next(orgRole);
    if (this.settings.subscriptionServiceApi) {
      await this.productService.fetchAllOwned();
    }
  }

  async getCurrentRole(): Promise<OrganisationRoleModel> {
    return this.currentOrganisationRoleSubject.value;
  }

}
