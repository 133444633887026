<div class="page-container">
  <div class="form-container">
    <div class="mfa-list-title">
      <h2>MFA List</h2>
      <div>
        <button mat-raised-button color="primary" (click)="addNewMfa()">+ Add MFA Number</button>
      </div>
    </div>
    <p class="title-description">Your active recovery methods are below. You may choose one to send a code to each time you log in</p>
    <div class="mfa-list-row" *ngFor="let mfaOption of activeMfaNumbers">
      <p>{{mfaOption.displayName}}: {{mfaOption.phoneNumber}}</p>
      <div>
        <button mat-raised-button color="warn" (click)="removeMfa(mfaOption)">Remove</button>
      </div>
    </div>
    <div *ngIf="!activeMfaNumbers.length">
      <p>No MFA methods in use</p>
    </div>
  </div>
</div>
