<!-- STAGE 1: Choose MFA -->
<form ngNoForm [formGroup]="selectedMfaForm" class="verify-container" *ngIf="verifyStage === 1 && mfaResolver?.hints.length">
  <div id="recaptcha-container"></div>
  <h2 *ngIf="showTitle">Verify MFA</h2>
  <label>Which MFA Method would you like to use?</label>
  <mat-radio-group formControlName="selectedMfaOption">
    <mat-radio-button *ngFor="let mfaOption of mfaResolver?.hints" [value]="mfaOption">
      "{{mfaOption.displayName}}": {{mfaOption.phoneNumber}}
    </mat-radio-button>
  </mat-radio-group>
  <button color="primary" mat-raised-button [disabled]="!selectedMfaForm.valid" (click)="chooseMfa()"> Send Verification </button>
</form>

<!-- STAGE 2: Verify MFA -->
<form ngNoForm [formGroup]="mfaCodeForm" class="verify-container" *ngIf="verifyStage === 2">
  <h2 *ngIf="showTitle">Verify MFA</h2>
  <p>A code has been sent to your MFA method</p>
  <mat-form-field appearance="outline">
    <mat-label> MFA Code </mat-label>
    <input matInput type="text" formControlName="mfaCode">
    <mat-error *ngIf="mfaCodeForm.controls.mfaCode.hasError('required')">
      MFA code is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <button color="primary" mat-raised-button [disabled]="!mfaCodeForm.valid" (click)="submitMfa()"> Verify </button>
</form>

<p class="verify-error" *ngIf="verifyError">{{verifyError}}</p>