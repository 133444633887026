import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrganisationInvitationService } from '../../../../../features/invitations/organisations/services/organisation-invitation/organisation-invitation.service';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';

@Component({
  selector: 'lib-organisation-invitation-dialog',
  templateUrl: './organisation-invitation-dialog.component.html',
  styleUrls: ['./organisation-invitation-dialog.component.scss']
})
export class OrganisationInvitationDialogComponent implements OnInit {
  invitationForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<OrganisationInvitationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private organisationInvitationService: OrganisationInvitationService,
    private usersSnackbarService: UsersSnackbarService,
  ) { }

  ngOnInit(): void {
    this.invitationForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      orgId: new FormControl('')
    });

    if (this.data?.restrictedDomain) {
      this.invitationForm.controls.email.addValidators(Validators.pattern(`^[A-Za-z0-9._%+-]+@${this.data.restrictedDomain}$`))
    }
  }

  sendInvitation(): void {
    this.invitationForm.value.orgId = this.data.organisationId;
    this.organisationInvitationService.post(`${this.invitationForm.value.orgId}/invitations`, this.invitationForm.value).then(res => {
      this.dialogRef.close();
      this.usersSnackbarService.open('Invitation has been sent');
    }).catch((err) => {
      this.dialogRef.close();
      this.usersSnackbarService.open(err.err.message);
    });
  }

}
