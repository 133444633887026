import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { EditProfileService } from '../../../services/edit-profile.service';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';

import firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  selector: 'suvo-users-add-mfa-dialog',
  templateUrl: './add-mfa-dialog.component.html',
  styleUrls: ['./add-mfa-dialog.component.scss']
})
export class AddMfaDialogComponent implements OnInit {

  reCaptchaObject: firebase.auth.RecaptchaVerifier;

  verifyStatus: 'receiving-code' | 'submitting-code' | 'enter-code' | 'success' | 'error' = 'receiving-code';
  errorMessage: string;
  verifyId: string;

  form: FormGroup = new FormGroup({
    displayName: new FormControl(this.data?.displayName || '', [Validators.required, Validators.maxLength(20)]),
    phoneNumber: new FormControl(this.data?.phoneNumber || '', [Validators.required, Validators.pattern(new RegExp(/^\+(?:[0-9]?){6,14}[0-9]$/))]),
    password: new FormControl('', Validators.required),
  });

  codeFormControl = new FormControl('', [Validators.required, Validators.pattern(new RegExp(/^[0-9]{6}$/))]);

  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    private readonly dialogRef: MatDialogRef<AddMfaDialogComponent>,
    private readonly profileManagementService: EditProfileService,
    private readonly usersSnackbarService: UsersSnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.reCaptchaObject = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible'
    });
  }

  async submitDetails(): Promise<void> {
    if (this.form.valid) {
      try {
        const test = await this.profileManagementService.reAuthenticateLocalUser(this.form.controls.password.value);
        this.stepper.selected.completed = true;
        this.stepper.next();
        this.receiveVerificationCode();
      } catch (err) {
        console.log(err);
        switch (err.code) {
          case 'auth/wrong-password':
            this.form.controls.password.setErrors({ 'invalid-pass': true });
            break;

          case 'auth/too-many-requests':
            this.errorMessage = err.message;
            this.verifyStatus = 'error';
            this.stepper.next();
            break;

          case 'auth/invalid-phone-number':
            this.form.controls.phoneNumber.setErrors({ pattern: true });
            break;

          default:
            this.verifyStatus = 'error';
            this.errorMessage = err.message;
            break;
        }
      }

    }
  }

  async submitCode(): Promise<void> {
    if (this.codeFormControl.valid) {
      // this.verifyStatus = 'submitting-code';

      try {
        const result = await this.profileManagementService.completeMfaVerification(
          this.verifyId,
          this.codeFormControl.value,
          this.form.value.displayName,
        );
        this.verifyStatus = 'success';
        this.verifyId = null;
        this.dialogRef.close({ success: true });
        this.usersSnackbarService.open('New MFA has been created successfully');
      } catch (err) {
        switch (err.code) {
          case 'auth/invalid-verification-code' || 'INVALID_CODE':
            this.verifyStatus = 'enter-code';
            this.codeFormControl.setErrors({ 'invalid-code': true });
            break;

          default:
            this.verifyStatus = 'error';
            this.errorMessage = err.message;
            break;
        }
      }
    }

  }

  async receiveVerificationCode(): Promise<void> {

    this.verifyStatus = 'receiving-code';

    try {
      const result = await this.profileManagementService.startMfaVerification(
        this.form.value.phoneNumber,
        this.reCaptchaObject
      );
      this.verifyId = result;
      this.verifyStatus = 'enter-code';
    } catch (error) {
      console.log(error);
      this.verifyStatus = 'error';
      this.errorMessage = error.message;
    }
  }

}
