<div class="register-form-container">
  <form ngNoForm class="register-form" [formGroup]="registerForm" *ngIf="registerForm && !registrationComplete">
    <p *ngIf="registerMode === 'invitation'">
      Hi {{ registerForm.controls.email.value }} thanks for accepting the invitation please create a new account by filling the following form.
    </p>
    <mat-form-field appearance="outline" *ngIf="this.registerForm.get('firstName')">
      <mat-label> First Name </mat-label>
      <input matInput formControlName="firstName" required>
      <mat-error *ngIf="registerForm?.controls.firstName.hasError('required')">
        First name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="this.registerForm.get('lastName')">
      <mat-label> Last Name </mat-label>
      <input matInput formControlName="lastName" required>
      <mat-error *ngIf="registerForm?.controls.lastName.hasError('required')">
        Last name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label> Email </mat-label>
      <input matInput formControlName="email" required>
      <mat-error *ngIf="registerForm?.controls.email.hasError('email') && !registerForm?.controls.email.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="registerForm?.controls.email.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="registerForm?.controls.email.hasError('emailExists')">
        Email is <strong>already in use</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="this.registerForm.get('gender')">
      <mat-label> Gender </mat-label>
      <mat-select formControlName="gender">
        <mat-option [value]="null">None</mat-option>
        <mat-option *ngFor="let gender of genders" [value]="gender">{{gender}}</mat-option>
      </mat-select>
      <mat-error *ngIf="registerForm?.controls.gender.hasError('required')">
        Gender is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="this.registerForm.get('ethnicity')">
      <mat-label> Ethnicity </mat-label>
      <mat-select formControlName="ethnicity">
        <mat-option [value]="null">None</mat-option>
        <mat-option *ngFor="let ethnicity of ethnicityList" [value]="ethnicity">{{ethnicity}}</mat-option>
      </mat-select>
      <mat-error *ngIf="registerForm?.controls.ethnicity.hasError('required')">
        Ethnicity is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="showRegisterDoBField && this.registerForm.get('dateOfBirth')">
      <mat-label>Date of Birth</mat-label>
      <input type="date" matInput formControlName="dateOfBirth">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label> Password </mat-label>
      <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="password" required>
      <button *ngIf="enablePasswordPreview" mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass">
        <mat-icon> {{hidePass ? 'visibility_off' : 'visibility'}} </mat-icon>
      </button>
      <mat-error *ngIf="registerForm?.controls.password.hasError('required')">
        Password is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="registerForm?.controls.password.hasError('pattern')">
        Passwords must be 8 characters and contain at least 1 lowercase, uppercase and numeric
        character.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label> Confirm Password </mat-label>
      <input matInput [type]="hideConfirmPass ? 'password' : 'text'" formControlName="confirmPassword" required [errorStateMatcher]="matcher">
      <button *ngIf="enablePasswordPreview" mat-icon-button matSuffix (click)="hideConfirmPass = !hideConfirmPass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPass">
        <mat-icon> {{hideConfirmPass ? 'visibility_off' : 'visibility'}} </mat-icon>
      </button>
      <mat-error *ngIf="registerForm?.hasError('notSame')">
        Passwords must match!
      </mat-error>
    </mat-form-field>

    <mat-checkbox *ngFor="let consentBox of consentBoxes; let i = index" [formControlName]="'consentBox'+i" class="consent-box">{{consentBox.checkboxText}}</mat-checkbox>

    <button class="register-btn" mat-raised-button type="submit" [disabled]="!registerForm?.valid || loading" (click)="onSubmit()"> Register </button>
  </form>
  <div *ngIf="registrationComplete">
    <email-verification-message [email]="registerForm.get('email')?.value"></email-verification-message>
  </div>
</div>