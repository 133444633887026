import { NgModule, ModuleWithProviders } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { SuvoUsersClientLibSettingsService } from './shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { TokenInterceptor } from './features/authentication/interceptors/token/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { IdentityService } from './shared/services/identity/identity.service';
import { AuthenticationModule } from './features/authentication/modules/authentication/authentication.module';
import { BranchService } from './features/branch/services/branch/branch.service';
import { CommonOrganisationService } from './features/organisation/services/common-organisation/common-organisation.service';
import { UnverifiedEmailInterceptor } from './features/authentication/interceptors/unverified-email/unverified-email.interceptor';
import { OrganisationInvitationModule } from './features/invitations/organisations/modules/organisation-invitation/organisation-invitation.module';
import { OrganisationModule } from './features/organisation/modules/organisation/organisation.module';
import { SubscriptionModule } from './features/subscription/modules/subscription.module';
import { SubscriptionService } from './features/subscription/services/subscription.service';
import { MyAccountModule } from './features/my-account/modules/my-account.module';
import { TenantRoleService } from './features/tenant-role/services/tenant-role.service';
import { UsersSnackbarService } from './shared/services/users-snackbar/users-snackbar.service';
import { UsersDialogService } from './shared/services/users-dialog/users-dialog.service';
import { SelectedOrganisationInterceptor } from './features/authentication/interceptors/selected-organisation/selected-organisation.interceptor';
import { SuvoCoreClientLibModule } from '@suvo-bi-core';
import { OrganisationService } from './features/organisation/services/organisation/organisation.service';

@NgModule({
  declarations: [],
  imports: [
    AngularFireAuthModule,
    AuthenticationModule,
    SuvoCoreClientLibModule,
    OrganisationInvitationModule,
    OrganisationModule,
    SubscriptionModule,
    MyAccountModule,
  ],
  exports: [
    AuthenticationModule,
    AngularFireAuthModule,
    AngularFireModule,
    OrganisationInvitationModule,
    MyAccountModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnverifiedEmailInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SelectedOrganisationInterceptor, multi: true },
    IdentityService,
    UsersSnackbarService,
    UsersDialogService,
    OrganisationService,
    // OrganisationRoleService,
    BranchService,
    CommonOrganisationService,
    SubscriptionService,
    TenantRoleService
  ],
})
export class SuvoUsersClientLibModule {
  static forRoot(config: any): ModuleWithProviders<SuvoUsersClientLibModule> {
    console.log('CONFIG', config);
    const suvoUsersClientLibSettings = new SuvoUsersClientLibSettingsService(config);
    return {
      ngModule: SuvoUsersClientLibModule,
      providers: [
        {
          provide: SuvoUsersClientLibSettingsService,
          useValue: suvoUsersClientLibSettings.config
        }
      ]
    };
  }
}

