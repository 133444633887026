import { CommonModel } from '@suvo-bi-core';

export class OrganisationModel extends CommonModel<OrganisationModel> {
  _id!: string;
  name!: string;
  tenantId!: string;
  constructor() {
    super(OrganisationModel);
  }
}
