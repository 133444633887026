import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'suvo-users-card-entry',
  templateUrl: './card-entry.component.html',
  styleUrls: ['./card-entry.component.scss']
})
export class CardEntryComponent implements OnInit {

  @Input() label: string;
  @Input() entryControl: FormControl | AbstractControl;
  @Input() forbidEdit = false;
  @Output() entrySaved: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputElement') set content(input: ElementRef) {
    if (input) {
      input.nativeElement.focus();
    }
  }

  editMode = false;
  beforeEditValue: any;

  constructor() { }

  ngOnInit(): void {
  }

  finish(save: boolean) {
    if (save) {
      if (this.beforeEditValue !== this.entryControl.value) {
        this.entrySaved.emit();
      }
    } else if (this.beforeEditValue) {
      this.entryControl.setValue(this.beforeEditValue);
    }
    this.editMode = false;
  }

  edit() {
    this.beforeEditValue = this.entryControl?.value;
    this.editMode = true;
  }

}
