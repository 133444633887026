import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationInvitationDialogComponent } from '../../components/organisation-invitation-dialog/organisation-invitation-dialog.component';
import { SharedModule } from '../../../../../shared/modules/shared/shared.module';
import { OrganisationInvitationService } from '../../services/organisation-invitation/organisation-invitation.service';
import { OrganisationInvitationValidationComponent } from '../../components/organisation-invitation-validation/organisation-invitation-validation.component';
import { InvitationListComponent } from '../../components/invitation-list/invitation-list.component';



@NgModule({
  declarations: [OrganisationInvitationDialogComponent, OrganisationInvitationValidationComponent, InvitationListComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    OrganisationInvitationDialogComponent, OrganisationInvitationValidationComponent, InvitationListComponent
  ],
  providers: [OrganisationInvitationService]
})
export class OrganisationInvitationModule { }
