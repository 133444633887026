<div fxLayout="column" fxLayoutAlign="start center" class="home-container" [formGroup]="lookupForm">
  <h2> Enter patient details and PIN provided </h2>
  <br><br>
  <div fxLayout="row wrap" fxLayoutAlign.lt-sm="center center" fxLayoutAlign="space-between center" class="home">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" autocomplete="new-password">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" autocomplete="new-password">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill">
        <mat-label>Date of Birth</mat-label>
        <input type="date" matInput formControlName="dateOfBirth">
      </mat-form-field>
    </div>
  </div>
  <br><br>
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-form-field appearance="fill">
      <mat-label>PIN</mat-label>
      <input type="password" matInput formControlName="pin">
    </mat-form-field>
    <p class="error-message" *ngIf="!isArray(currentError?.message) && isString(currentError?.message)">Error: <br>{{currentError.message}}</p>
    <ng-container *ngIf="isArray(currentError?.message)">
      <p class="error-message list-header">Errors:</p>
      <ul>
        <li class="error-list" *ngFor="let message of currentError.message">{{message}}</li>
      </ul>
    </ng-container>
    <br>
    <p class="error-message" *ngIf="!currentError?.message">&nbsp;</p>
    <button color="primary" class="view-btn" mat-raised-button (click)="submitLookup()" [disabled]="loading">View Patient</button>
    <br>
    <suvo-core-loading-spinner *ngIf="loading" [message]="'Loading Forms: ' + loadingPercentage + '%'"></suvo-core-loading-spinner>
  </div>
</div>