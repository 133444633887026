import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  showFields = [
    {
      name: 'firstName',
      required: true
    },
    {
      name: 'lastName',
      required: true
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
