export const ethnicities = [
  'White British',
  'White Irish',
  'Any other White background',
  'White  Caribbean',
  'White African',
  'Black Caribbean',
  'Black African',
  'White and Asian',
  'Any other mixed background',
  'Indian',
  'Pakistani',
  'Bangladeshi',
  'Asian British Indian',
  'Asian British Pakistani',
  'Asian British Bangladeshi',
  'Any other Asian background',
  'Black Caribbean',
  'Black African',
  'Black British Caribbean',
  'Black British African',
  'Any other Black background',
  'Chinese',
  'Any other ethnic group',
  'Not stated'
];
