<div class="page-container">
  <div class="form-container">
    <div class="form-container-header">
      <h2>Edit Profile</h2>
    </div>

    <form class="edit-form" [formGroup]="profileForm" *ngIf="profileForm">
      <mat-form-field appearance="outline">
        <mat-label> First Name </mat-label>
        <input matInput formControlName="firstName" required>
        <mat-error *ngIf="profileForm.controls.firstName.hasError('required')">
          First name is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> Last Name </mat-label>
        <input matInput formControlName="lastName" required>
        <mat-error *ngIf="profileForm.controls.lastName.hasError('required')">
          Last name is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="this.profileForm.get('gender')">
        <mat-label> Gender </mat-label>
        <mat-select formControlName="gender">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let gender of genders" [value]="gender">{{gender}}</mat-option>
        </mat-select>
        <mat-error *ngIf="profileForm?.controls.gender.hasError('required')">
          Gender is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="this.profileForm.get('ethnicity')">
        <mat-label> Ethnicity </mat-label>
        <mat-select formControlName="ethnicity">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let ethnicity of ethnicityList" [value]="ethnicity">{{ethnicity}}</mat-option>
        </mat-select>
        <mat-error *ngIf="profileForm?.controls.ethnicity.hasError('required')">
          Ethnicity is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="this.profileForm.get('dateOfBirth')">
        <mat-label>Date of Birth</mat-label>
        <input type="date" matInput formControlName="dateOfBirth">
      </mat-form-field>

    </form>
    <button mat-raised-button color="primary" [disabled]="!profileForm?.valid || !profileForm?.dirty" (click)="applyProfileChanges()"> Apply Changes </button>
    <!-- TODO: Check how well the other change password functionality works with this -->
    <!-- <p class="change-pass-link">Change Password</p> -->

  </div>
</div>