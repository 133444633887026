import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidationErrors, AbstractControl, FormGroupDirective, NgForm, ValidatorFn } from '@angular/forms';

import { ErrorStateMatcher } from '@angular/material/core';
import { UsersService } from '../../../../users/services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ethnicities } from '../../../../../shared/constants/ethnicity-list';
import { DatePipe } from '@angular/common';
import { SuvoUsersClientLibSettingsService } from '../../../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { IRegisterConsentCheckbox } from '../../../interfaces/consent-box.interface';

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.dirty && control.parent?.get('password')?.value !== control.parent?.get('confirmPassword')?.value);
  }
}

@Component({
  selector: 'lib-page-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [DatePipe]
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  registrationComplete = false;
  matcher: MyErrorStateMatcher;
  passRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  token: string;
  registerMode: string;
  invitationEmail: string;
  genders = ['Male', 'Female', 'Other'];
  ethnicityList = ethnicities;
  showRegisterDoBField = false;
  hidePass = true;
  hideConfirmPass = true;
  loading = false;

  @Input() showFields: [{ name: string, required: boolean }];
  @Input() consentBoxes: IRegisterConsentCheckbox[];
  @Input() enablePasswordPreview: boolean;
  @Output() onRegisterSuccess = new EventEmitter<void>();

  constructor(
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private suvoUsersClientLibSettingsService: SuvoUsersClientLibSettingsService
  ) { }

  ngOnInit(): void {
    this.registerForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.pattern(this.passRegex)]),
      confirmPassword: new FormControl('', [Validators.required])
    }, {
      validators: [this.checkPasswordsMatch, /*this.requireCheckboxesToBeCheckedValidator(1)*/]
    });
    this.matcher = new MyErrorStateMatcher();

    if (this.showFields?.length) {
      this.showFields.forEach((formControl) => {
        switch (formControl.name) {
          case 'dateOfBirth':
            if (formControl.required) {
              this.registerForm.addControl(formControl.name, new FormControl(null, [Validators.required]));
            } else {
              this.registerForm.addControl(formControl.name, new FormControl(null));
            }
            break;

          default:
            if (formControl.required) {
              this.registerForm.addControl(formControl.name, new FormControl('', [Validators.required]));
            } else {
              this.registerForm.addControl(formControl.name, new FormControl(''));
            }
            break;
        }
      });
    }

    // TODO: Long, probably inefficient, optimise in future
    if (this.consentBoxes?.length) {
      let ensureAtLeastOneConsentCheckBoxChecked = (control: AbstractControl): ValidationErrors | null => {
        let totalChecked = 0;
        this.consentBoxes.forEach((consentBox, index) => {
          if (this.registerForm.controls[`consentBox${index}`]?.value == true) {
            totalChecked++;
          }
        });

        if (totalChecked == 0) {
          return { requireCheckboxesToBeChecked: true };
        }

        return null;
      }

      this.consentBoxes.forEach((consentBox, index) => {
        this.registerForm.addControl(`consentBox${index}`, new FormControl(false, [ensureAtLeastOneConsentCheckBoxChecked]));

        this.registerForm.controls[`consentBox${index}`].valueChanges.subscribe((newValue: string) => {

          let checked = 0;

          Object.keys(this.registerForm.controls).forEach(key => {
            const control = this.registerForm.controls[key];

            if (key.includes("consentBox") && control.value === true) {
              checked++;
            }
          });

          if (checked < 1) { // TODO: MAKE CUSTOM
            Object.keys(this.registerForm.controls).forEach(key => {
              const control = this.registerForm.controls[key];

              if (key.includes("consentBox")) {
                control.enable({ emitEvent: false });
              }
            });
            this.registerForm.controls.consentBox0.setErrors({
              requireCheckboxesToBeChecked: true,
            })
            return null;
          }

          Object.keys(this.registerForm.controls).forEach(key => {
            const control = this.registerForm.controls[key];

            if (control.value === false && key.includes("consentBox")) {
              control.disable({ emitEvent: false });
            }
          });
          return null;
        });
      })
    }

    this.activatedRoute.params.pipe(take(1)).subscribe(params => {
      if (Object.keys(params).length) {
        this.token = params.token;
        this.invitationEmail = params.email;
        this.registerForm.controls.email.setValue(params.email);
        this.registerForm.controls.email.disable();
        this.registerMode = 'invitation';
      } else {
        this.registerMode = 'classic';
      }
    });
    this.showRegisterDoBField = this.suvoUsersClientLibSettingsService.showRegisterDoBField;
  }

  checkPasswordsMatch = (control: AbstractControl): ValidationErrors | null => {
    if (this.registerForm) {
      return this.registerForm.get('password')?.value === this.registerForm.get('confirmPassword')?.value ? null : { notSame: true };
    }
    return null;
  }

  onSubmit(): void {
    if (this.registerMode === 'invitation') {
      this.registerForm.controls.email.enable();
      this.registerForm.patchValue({ email: this.invitationEmail });
      this.registerForm.addControl('token', new FormControl(this.token, Validators.required));
      this.register('public/invites/register');
    } else {
      this.register('public/register');
    }
  }

  async register(route: string): Promise<void> {
    this.loading = true;
    try {
      if (this.registerForm.value?.dateOfBirth) {
        this.registerForm.value.dateOfBirth = this.datePipe.transform(this.registerForm.value.dateOfBirth, 'yyyy-MM-dd');
      }
      const user = await this.usersService.registerUser(route, this.registerForm.value);
      if (!user.user.emailVerified) {
        this.registrationComplete = true;
        this.onRegisterSuccess.emit();
      } else {
        this.router.navigateByUrl(`private/invitation-list?token=${this.token}`);
      }
    } catch (err) {
      if (err.error.code === 'auth/email-already-exists') {
        this.registerForm.controls.email.setErrors({ emailExists: true });
      }
    }
    this.loading = false;
  }

}

  // TODO: Might be handy in future for refactoring, don't delete just yet
  // requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  //   return function validate(formGroup: FormGroup) {
  //     let checked = 0;

  //     Object.keys(formGroup.controls).forEach(key => {
  //       const control = formGroup.controls[key];

  //       if (control.value === true) {
  //         checked++;
  //       }
  //     });

  //     if (checked < minRequired) {
  //       return {
  //         requireCheckboxesToBeChecked: true,
  //       };
  //     }

  //     Object.keys(formGroup.controls).forEach(key => {
  //       const control = formGroup.controls[key];

  //       debugger;
  //       if (control.value === false && key.includes("consentBox")) {
  //         control.disable();
  //       }
  //     });

  //     return null;
  //   };
  // }

