import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from './public.component';
// import { firebaseUiAuthConfig } from 'src/environments/environment';
// import { FirebaseUIModule } from 'firebaseui-angular';
import { SharedModule } from '../../shared/modules/shared.module';
import { AppRoutingModule } from '../../../app/app-routing.module';
import { VerifyComponent } from './verify/verify.component';
import { EmailVerificationMessageComponent } from './email-verification-message/email-verification-message/email-verification-message.component';
import { WrongPlatformMessageComponent } from './wrong-platform-message/wrong-platform-message.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

@NgModule({
  declarations: [
    PublicComponent,
    VerifyComponent,
    EmailVerificationMessageComponent,
    WrongPlatformMessageComponent,
    LoginComponent,
    RegisterComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    // FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    SharedModule
  ]
})
export class PublicModule { }
