import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';

// TODO: Only import the components we need, not the whole thing
import firebase from 'firebase/app';
import 'firebase/auth';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';

@Component({
  selector: 'lib-remove-mfa',
  templateUrl: './remove-mfa.component.html',
  styleUrls: ['./remove-mfa.component.scss']
})
export class RemoveMfaComponent implements OnInit, OnDestroy {

  localFirebaseUser: firebase.User;
  mfaOption: firebase.auth.MultiFactorInfo;
  reAuthPassword = '';
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  removeStage = 1;
  mfaResolver: any;
  reAuthPasswordForm: FormGroup;
  hide = true;

  errMessage = '';

  constructor(
    private dialogRef: MatDialogRef<RemoveMfaComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private usersSnackbarService: UsersSnackbarService,
  ) { }

  ngOnInit(): void {
    this.localFirebaseUser = this.data.firebaseUser; // TODO: Doing this to get the right types, can this be done better?
    this.mfaOption = this.data.targetMfaOption;
    this.reAuthPasswordForm = new FormGroup({
      reAuthPassword: new FormControl('', Validators.required)
    });
  }

  async submit(): Promise<void> {
    const reAuthCred: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(
      this.localFirebaseUser.email,
      this.reAuthPasswordForm.value.reAuthPassword
    );

    try {
      await this.localFirebaseUser.reauthenticateWithCredential(reAuthCred);
      await this.localFirebaseUser.multiFactor.unenroll(this.mfaOption);
    } catch (err) {
      switch (err.code) {
        case 'auth/multi-factor-auth-required':
          this.removeStage = 2;
          this.mfaResolver = err.resolver;
          break;

        case 'auth/wrong-password':
          this.errMessage = err.message;
          break;

        default:
          console.log(err);
          this.usersSnackbarService.open('An error ocurred whilst removing this MFA method.');
          this.errMessage = err.message;
          break;
      }
    }
  }

  async mfaSuccess(): Promise<void> {
    try {
      await this.localFirebaseUser.multiFactor.unenroll(this.mfaOption);
      this.dialogRef.close({ success: true });
      this.usersSnackbarService.open('Your MFA phone number has been removed successfully');
    } catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
