<h4 mat-dialog-title>{{ data?.title || 'No title?' }}</h4>


<div>
  <ng-container [ngSwitch]="removeStatus">

    <ng-container *ngSwitchCase="'enter-password'">
      <div mat-dialog-content>
        <suvo-users-password-field *ngIf="passwordFormControl" [localFormControl]="passwordFormControl"></suvo-users-password-field>
      </div>
      <div mat-dialog-actions>
        <button mat-flat-button [mat-dialog-close]="">Cancel</button>
        <button mat-flat-button (click)="submitPassword()" color="primary" [disabled]="passwordFormControl.invalid">Submit</button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'loading'">
      <suvo-core-loading-spinner message="Sending"></suvo-core-loading-spinner>
    </ng-container>

    <ng-container *ngSwitchCase="'verify-mfa'">
      <lib-verify-mfa [mfaResolver]="mfaResolver" [showTitle]="false" (mfaSuccess)="mfaSuccess()"></lib-verify-mfa>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <div fxLayout="column" fxLayoutAlign="center center">
        <suvo-core-error-message title="Failed to remove!" [message]="errorMessage"></suvo-core-error-message>
        <p>Please try again!</p>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'success'">
      <suvo-core-success-message title="Success" message="This option has been removed!"></suvo-core-success-message>
    </ng-container>

  </ng-container>
</div>