import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/modules/shared/shared.module';
import { EditProfileComponent } from '../pages/edit-profile/edit-profile.component';

@NgModule({
  declarations: [
    EditProfileComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    EditProfileComponent
  ]
})
export class MyAccountModule { }
