import { Injectable } from '@angular/core';
import { ApiService } from '@suvo-bi-core';
import { OrganisationRoleService, CommonOrganisationService, SuvoUsersClientLibSettingsService } from '@suvo-bi-users';
import { AnimalModel } from '../../interfaces/animal.model';

@Injectable({
  providedIn: 'root'
})
export class AnimalService extends CommonOrganisationService<AnimalModel> {
  constructor(
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService,
    organisationRoleService: OrganisationRoleService
    ) {
    super(AnimalModel, apiService, settings, 'animals', organisationRoleService);
  }
}
