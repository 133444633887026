import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UsersDialogService {

  identityDialogConfig: MatDialogConfig = {
    width: '368px',
  }

  constructor(
    private readonly matDialog: MatDialog,
  ) { }

  open(component: ComponentType<unknown>, data?: object): MatDialogRef<unknown> {
    return this.matDialog.open(component, { data, ...this.identityDialogConfig });
  }

}
