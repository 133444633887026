import { CommonModel } from '@suvo-bi-core';
import { FormControlModel } from './form-control.model';
import { FormStructureModel } from './form-structure.model';

export class FormDefinitionModel extends CommonModel<FormDefinitionModel> {
  name: string;
  title: string;
  backgroundColor: string;
  formStructure: FormStructureModel;
  formControls: FormControlModel[];
  type: 'MUST' | 'NEED' | 'SHOULD' | 'EPILEPSY' | 'MENTAL' | 'AUTISM' | 'PAIN' | 'ADJUSTMENTS';
  constructor() {
    super(FormDefinitionModel);
  }
}
