import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UsersSnackbarService {

  snackbarConfig: MatSnackBarConfig = {
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    duration: 2000,
  }

  constructor(
    private readonly matSnackbar: MatSnackBar
  ) { }

  open(message: string, extraClasses?: string | string[]) {
    this.matSnackbar.open(message, null, { panelClass: extraClasses, ...this.snackbarConfig });
  }

  openError(message: string) {
    this.open(message, 'error');
  }

  openFailure(message: string) {
    this.open(message, 'failure');
  }

}
