import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TableService } from '../../../shared/table/services/table.service';

@Injectable({
  providedIn: 'root'
})
export class ClinicianService extends TableService {

  constructor(protected httpClient: HttpClient) {
    super(httpClient, "clinician")
  }

  getAllClinicians(): Promise<any> {
    return super.get(`${environment.usersServiceApi}clinician`)
  }
}
