import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { TenantRoleService } from '@suvo-bi-users';

@Component({
  selector: 'app-login-referrer',
  templateUrl: './login-referrer.component.html',
  styleUrls: ['./login-referrer.component.scss']
})
export class LoginReferrerComponent implements OnInit {

  constructor(
    private router: Router,
    private tenantRoleService: TenantRoleService,
    private angularFireAuth: AngularFireAuth
    ) { }

  async ngOnInit(): Promise<void> {
    const role = await this.tenantRoleService.loadRole();
    if (role.role === 'ADMIN') {
      this.router.navigate(['private', 'patient-list']);
    } else if (role.role === 'CLINICIAN') {
      this.router.navigate(['private', 'dashboard']);
    } else {
      await this.angularFireAuth.signOut();
      this.router.navigate(['public', 'wrong-platform-message']);
    }
  }

}
