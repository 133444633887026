import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { OrganisationRoleService } from '../../../organisation-role/services/organisation-role.service';
import { OrganisationRoleModel } from '../../../organisation-role/models/organisation-role.model';

@Injectable()
export class SelectedOrganisationInterceptor implements HttpInterceptor {

  constructor(private organisationRoleService: OrganisationRoleService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.organisationRoleService.currentOrganisationRoleSubject.pipe(
      take(1),
      switchMap((selectedOrgRole: OrganisationRoleModel) => {
        let clone = req.clone();
        if (selectedOrgRole) {
          clone = clone.clone({ headers: req.headers.set('Selected-Organisation-Id', `${selectedOrgRole.organisationId}`) });
        }
        console.log('INTERCEPTOR ORG', selectedOrgRole)
        return next.handle(clone);
      })
    );
  }
}
