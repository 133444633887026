import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { OrganisationInvitationService } from '../../../../../features/invitations/organisations/services/organisation-invitation/organisation-invitation.service';
import { IdentityService } from '../../../../../shared/services/identity/identity.service';

@Component({
  selector: 'organisation-invitation-validation',
  templateUrl: './organisation-invitation-validation.component.html',
  styleUrls: ['./organisation-invitation-validation.component.scss']
})
export class OrganisationInvitationValidationComponent implements OnInit {
  token: string;
  constructor(private activateRoute: ActivatedRoute,
    private organisationInvitationService: OrganisationInvitationService,
    public router: Router,
    private identityService: IdentityService
  ) { }

  ngOnInit(): void {
    this.activateRoute.queryParams.pipe(take(1)).subscribe(params => {
      this.token = params['token'];
    })
    if (this.token) {
      this.validateInvitation();
    }
  }

  validateInvitation() {
    this.organisationInvitationService.post(`invitation/${this.token}`).then((invitation: any) => {
      const { token, email, message } = invitation;

      this.identityService.firebaseUserSubject.pipe(take(1)).subscribe(user => {
        if (user?.email && user.email !== email) {
          this.router.navigateByUrl(`private/invitation-list?token=${token}`);
        } else {
          this.redirectUser(message, invitation, token, email);
        }
      });
    })
  }


  redirectUser(message, invitation, token, email) {
    if (message === 'Account-exists') {
      this.router.navigate(['public', 'login', token, email]);
    } else if (message === 'Account-does-not-exist') {
      console.log('no account' + invitation);
      this.router.navigate(['public', 'register', token, email]);
    } else {
      this.router.navigate(['public', 'login']);
    }
  }
}



