<h4 mat-dialog-title>EMail Change Request Sent</h4>
<div mat-dialog-content>
  <p>A request to change your email address has been sent to the <strong>new</strong> email address you have specified.</p>
  <p>To finalize the email change request, please do the following.</p>
  <ol>
    <li>Log out from this site.</li>
    <li>Check the inbox of the <strong>new</strong> email address for an email from us.</li>
    <li>Click the link within that email to confirm the change, wait for it to complete.</li>
    <li>Log back into this application.</li>
  </ol>
</div>