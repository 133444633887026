import { CommonModel } from '@suvo-bi-core';

export class TenantRoleModel extends CommonModel<TenantRoleModel> {
  firebaseUserId: string;
  role: string;
  tenantId: string;
  constructor() {
    super(TenantRoleModel);
  }
}
