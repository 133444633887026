import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IUser } from '../../authentication/interfaces/user.interface';
import { UserModel } from '../models/user-model';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { OrganisationRoleService } from '../../organisation-role/services/organisation-role.service';
import { CommonService, ApiService } from '@suvo-bi-core';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends CommonService<UserModel> {
  public userSubject: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  constructor(
    protected apiService: ApiService,
    private settings: SuvoUsersClientLibSettingsService,
    private angularFireAuth: AngularFireAuth,
    private organisationRoleService: OrganisationRoleService
  ) {
    super(UserModel, apiService, settings.usersServiceApi, '');
  }

  async registerUser(route: string, userInfo: any): Promise<firebase.default.auth.UserCredential> {
    return new Promise(async (resolve, reject) => {
      userInfo.tenantId = await this.angularFireAuth.tenantId;
      this.post(route, userInfo).then(async (res: any) => {
        try {
          const user = await this.angularFireAuth.signInWithEmailAndPassword(userInfo.email, userInfo.password);
          if (!user.user.emailVerified) {
            await user.user?.sendEmailVerification();
            await this.angularFireAuth.signOut();
          }
          resolve(user);
        } catch (err) {
          reject(err);
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async loadUser(): Promise<IUser> {
    const user = await this.getOne('me/profile/');
    this.userSubject.next(user);
    await this.organisationRoleService.getRoles();
    return user;
  }
}
