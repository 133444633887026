import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-verify-email-confirmation',
  templateUrl: './verify-email-confirmation.component.html',
  styleUrls: ['./verify-email-confirmation.component.scss']
})
export class VerifyEmailConfirmationComponent implements OnInit {
  response!: string;
  successfulResponse!: boolean;
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  constructor(private activatedRoute: ActivatedRoute, private angularFirebaseAuth: AngularFireAuth) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      if (params.mode === 'verifyEmail') {
        this.handleVerifyEmail(params.oobCode);
      } else {
        this.response = 'Something went wrong';
        this.successfulResponse = false;
      }
    });
  }

  handleVerifyEmail(actionCode: string): void {
    this.angularFirebaseAuth.applyActionCode(actionCode).then((res) => {
      this.response = 'Your email has been verified';
      this.successfulResponse = true;
    }).catch(() => {
      this.response = 'Something went wrong';
      this.successfulResponse = false;
    });
  }
}
