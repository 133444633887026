import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EditProfileService } from '../../../services/edit-profile.service';
import { AddMfaDialogComponent } from '../../dialogs/add-mfa-dialog/add-mfa-dialog.component';
import { RemoveMfaDialogComponent } from '../../dialogs/remove-mfa-dialog/remove-mfa-dialog.component';
import { UsersSnackbarService } from '../../../../../shared/services/users-snackbar/users-snackbar.service';
import { UsersDialogService } from '../../../../../shared/services/users-dialog/users-dialog.service';

@Component({
  selector: 'suvo-users-edit-mfa',
  templateUrl: './edit-mfa.component.html',
  styleUrls: ['./edit-mfa.component.scss']
})
export class EditMfaComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  activeDialogRef: MatDialogRef<unknown>;
  firebaseUser: firebase.default.User;
  mfaOptions: any[];

  constructor(
    private readonly profileManagementService: EditProfileService,
    private readonly usersDialogService: UsersDialogService,
    private readonly usersSnackbarService: UsersSnackbarService,
  ) { }

  ngOnInit(): void {
    this.profileManagementService.getFirebaseUserSubject().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(async (user) => {
      if (user?.uid) {
        // TODO: View won't update unless I put this timeout. Why!?
        setTimeout(() => {
          this.firebaseUser = user;
          this.mfaOptions = user.multiFactor.enrolledFactors;
        }, 500);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.overwriteActiveDialog(); // Close active dialog when leaving the component.
  }

  openAddMfaDialog(): void {

    const dialogRef = this.usersDialogService.open(AddMfaDialogComponent, {
      title: 'Add Authentication',
      firebaseUser: this.firebaseUser,
    });

    dialogRef.afterClosed().pipe(
      take(1),
      takeUntil(this.unsubscribe$),
    ).subscribe((data: any) => {
      // ? ? ?
    });

    this.overwriteActiveDialog(dialogRef);

  }

  async removeMfaOption(enrolledFactor: any): Promise<void> {
    try {
      const displayName = enrolledFactor.displayName;
      await this.firebaseUser.multiFactor.unenroll(enrolledFactor);
      this.usersSnackbarService.open(`${displayName} option removed.`);
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {

        const dialogRef = this.usersDialogService.open(RemoveMfaDialogComponent, {
          title: 'Remove Authentication',
          mfaOption: enrolledFactor,
        });

        this.overwriteActiveDialog(dialogRef);
      }
    }
  }


  overwriteActiveDialog(dialog?: MatDialogRef<unknown>): void {
    if (this.activeDialogRef) {
      this.activeDialogRef.close();
    }
    this.activeDialogRef = dialog;
  }

}
