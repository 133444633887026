<div class="table-filter-banner">
  <div class="search-box-container">
    <mat-form-field appearance="outline" *ngIf="searchBox" class="search-box">
      <mat-label>Search</mat-label>
      <input matInput [formControl]="searchBox">
    </mat-form-field>
  </div>
</div>

<div class="table-container" *ngIf="data$" >
  <div class="table-outer mat-elevation-z2">
    <table mat-table [dataSource]="data$ | async" matSort>
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by First Name"> First Name </th>
        <td mat-cell *matCellDef="let patient"> {{patient.firstName}} </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Last Name"> Last Name </th>
        <td mat-cell *matCellDef="let patient"> {{patient.lastName}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email"> Email </th>
        <td mat-cell *matCellDef="let patient"> {{patient.email}} </td>
      </ng-container>

      <ng-container matColumnDef="dateOfBirth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Date of Birth"> Date of Birth </th>
        <td mat-cell *matCellDef="let patient"> {{patient.dateOfBirth | date: 'mediumDate'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row" (click)="patientClicked(row)"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="pageOptions.pageSizes" showFirstLastButtons formFieldAppearance="fill">
    </mat-paginator>
  </div>

</div>