import { Injectable } from '@angular/core';
import { ApiService } from '@suvo-bi-core';
import { BranchRoleService, CommonBranchService, OrganisationRoleService, SuvoUsersClientLibSettingsService } from '@suvo-bi-users';
import { CarModel } from '../../interfaces/car.model';

@Injectable({
  providedIn: 'root'
})
export class CarService extends CommonBranchService<CarModel> {
  constructor(
    apiService: ApiService,
    settings: SuvoUsersClientLibSettingsService,
    organisationRoleService: OrganisationRoleService,
    branchRoleService: BranchRoleService
    ) {
    super(CarModel, apiService, settings, 'cars', organisationRoleService, branchRoleService);
  }
}
