import {Component, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {TableComponent} from '../../../shared/table/components/table.component';
import {PatientService} from '../../services/patient/patient.service';
import {PinDialogComponent} from './dialogs/pin-dialog/pin-dialog.component';
import {EmptyForms} from '../forms/const/empty-forms.const';
import {CompletedFormService} from '../../../shared/form-definition/services/completed-form.service';
import {FormDefinitionService} from '../../../shared/form-definition/services/form-definition.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent extends TableComponent<any> {
  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'dateOfBirth'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  forms = EmptyForms;

  currentError;
  loading = false;
  loadingPercentage = 0;
  itemsToLoad = 0;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected patientService: PatientService,
    protected formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private completedFormService: CompletedFormService,
    private formDefinitionService: FormDefinitionService
  ) {
    super(activatedRoute, patientService, formBuilder);
  }

  async ngOnInit(): Promise<void> {
    for (const type in this.forms) {
      if (Object.prototype.hasOwnProperty.call(this.forms, type)) {
        setTimeout(async () => {
          this.forms[type].definition = await this.formDefinitionService.getByType(type.toUpperCase());
        });
      }
    }

    super.ngOnInit();
  }

  async patientClicked(row): Promise<void> {
    try {
      const promiseArray = [];
      for (const key in this.forms) {
        if (this.forms[key].definition) {
          promiseArray.push(this.loadCompletedForm(key, row.firebaseUserId));
        }
      }

      this.itemsToLoad = promiseArray.length;
      this.loading = true;
      await Promise.all(promiseArray);

      this.router.navigate(['../patient-view'], {
        relativeTo: this.activatedRoute,
        state: {
          forms: this.forms,
          backTo: 'patient-list',
          patientId: row.firebaseUserId
        }
      });
      this.loading = false;
      this.loadingPercentage = 0;
    } catch (err) {
      this.loading = false;
      console.log(err);
      this.currentError = err?.error;
      if (this.isArray(this.currentError?.message) && (this.currentError?.message?.length === 1)) {
        this.currentError.message = this.currentError.message[0];
      }
      console.log(this.currentError);
    }
  }

  async loadCompletedForm(key: string, patientId) {
    this.forms[key].answers = await this.completedFormService.getPatientCompletedFormAsAdmin(this.forms[key].definition._id, patientId);
    this.loadingPercentage += Math.round(100 / this.itemsToLoad);
  }

  // TODO: Hacky...
  isArray(obj: any): boolean {
    if (obj) {
      return Array.isArray(obj);
    } else {
      return false;
    }
  }
}
