import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { RouteToggleGroupComponent } from './components/route-toggle-group/route-toggle-group.component';
import { SuccessMessageComponent } from './components/success-message/success-message.component';
import { IconMessageComponent } from './components/icon-message/icon-message.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [
    ErrorMessageComponent,
    LoadingSpinnerComponent,
    RouteToggleGroupComponent,
    SuccessMessageComponent,
    IconMessageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    ErrorMessageComponent,
    LoadingSpinnerComponent,
    RouteToggleGroupComponent,
    SuccessMessageComponent,
    IconMessageComponent,
  ]
})
export class CommonComponentsModule { }
