import { Injectable } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { Subject } from 'rxjs';
import { IBreadcrumbLink } from '../interfaces/breadcrumb-link.interface';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {

  displayNameSwaps = new Map<string, string>();
  nameSwapsModified = new Subject<boolean>();

  constructor(
    // private readonly router: Router,
    // private readonly activatedRoute: ActivatedRoute,
  ) { }

  buildBreadcrumb(route: ActivatedRoute, prefixUrl: string = '', breadcrumbs: IBreadcrumbLink[] = []): IBreadcrumbLink[] {

    const routeConfig: Route = route.routeConfig;
    const routeData: any = routeConfig?.data;

    let path = routeConfig?.path ?? '';
    let displayName: string;

    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');

    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
    }

    if (routeData?.breadcrumbDynamic) {
      displayName = this.displayNameSwaps.get(routeData.breadcrumbDynamic);
    } else if (routeData?.breadcrumb) {
      displayName = routeData?.breadcrumb;
    }

    if (displayName) {
      breadcrumbs.push({ displayName, route: `${prefixUrl}/${path}` })
    }

    if (route.firstChild) {
      const nextUrl = path ? `${prefixUrl}/${path}` : prefixUrl;
      return this.buildBreadcrumb(route.firstChild, nextUrl, breadcrumbs);
    }

    return breadcrumbs;

  }

  setDynamicNameSwap(key: string, value: string): void {
    this.displayNameSwaps.set(key, value);
    this.nameSwapsModified.next();
  }

  removeDynamicNameSwap(key: string): void {
    this.displayNameSwaps.delete(key);
  }

}
