import { Injectable } from '@angular/core';
import { SuvoUsersClientLibSettingsService } from '../../../shared/services/suvo-users-client-lib-settings/suvo-users-client-lib-settings.service';
import { BehaviorSubject } from 'rxjs';
import { TenantRoleModel } from '../models/tenant-role.model';
import { CommonService, ApiService } from '@suvo-bi-core';

@Injectable({
  providedIn: 'root'
})
export class TenantRoleService extends CommonService<TenantRoleModel> {
  currentRoleSubject: BehaviorSubject<TenantRoleModel> = new BehaviorSubject<TenantRoleModel>(null);

  constructor(apiService: ApiService, settings: SuvoUsersClientLibSettingsService) {
    super(TenantRoleModel, apiService, settings.usersServiceApi, 'me/tenant-roles');
  }

  setCurrentRole(role: TenantRoleModel): void {
    this.currentRoleSubject.next(role);
  }

  async getCurrentRole(): Promise<TenantRoleModel> {
    return this.currentRoleSubject.value;
  }

  async loadRole(): Promise<TenantRoleModel> {
    const role = await this.getOne('');
    this.setCurrentRole(role);
    return role;
  }
}
