import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { OrganisationRoleService } from '../../../organisation-role/services/organisation-role.service';
import { SubscriptionService } from '../../services/subscription.service';

import { StripeCardElementOptions } from '@stripe/stripe-js';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UsersSnackbarService } from '../../../../shared/services/users-snackbar/users-snackbar.service';
import { OrganisationRoleModel } from '../../../organisation-role/models/organisation-role.model';

@Component({
  selector: 'lib-manage-subscription',
  templateUrl: './manage-subscription.component.html',
  styleUrls: ['./manage-subscription.component.scss']
})
export class ManageSubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @Input() navType?: 'single' | 'multiple' | 'personal' = 'multiple';
  @Input() selectedOrgId?: string;

  organisationsList = [];

  selectedOrganisation = new FormControl();
  // selectedOrganisationName = new FormControl();
  selectedOrgCustomer;
  selectedOrgCustomerSubs;

  productList = [];

  clientSecret: string;

  loading = true;
  enteringPaymentInfo = false;
  dirtyCheckObj = {
    originalValues: {
      selectedProducts: [],
      seats: 0
    },
    isDirty: false
  };

  seats = new FormControl(1);
  seatsValueSub: Subscription;
  previewPrice = 0;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      },
    },
  };

  isOrganisationAdmin = true;

  constructor(
    private organisationRoleService: OrganisationRoleService,
    private subscriptionService: SubscriptionService,
    private stripeService: StripeService,
    private usersSnackbarService: UsersSnackbarService
  ) { }

  ngOnInit(): void {
    this.organisationRoleService.get('').then(async (res: any[]) => {
      this.organisationsList = res;

      if (this.selectedOrgId) {
        this.organisationsList.forEach((orgRole: OrganisationRoleModel) => {
          if (this.selectedOrgId === orgRole.organisationId) {
            this.selectedOrganisation.setValue(orgRole);
          }
        });
      } else {
        this.selectedOrganisation.setValue(res[0]);
      }

      if (this.navType === 'personal') {
        this.selectedOrganisation.setValue(res[0]);
      }

      try {
        this.selectedOrgCustomer = await this.subscriptionService.getOrganisationCustomer(this.selectedOrganisation.value.organisationId);
        await this.refreshProductsAndSubs();
        this.reCalculatePrice();
        this.seatsValueSub = this.seats.valueChanges.subscribe((value) => {
          if (value < 1) {
            this.seats.setValue(1);
          } else if (value > 9999999) {
            this.seats.setValue(9999999);
          }
          this.reCalculatePrice();
          this.checkIfDirty();
          this.isOrganisationAdmin = true;
        });
      } catch (error) {
        if (error.status === 403 && (error?.statusText === 'Forbidden' || error?.error?.error === 'Forbidden')) {
          this.loading = false;
          this.isOrganisationAdmin = false;
        }
      }
    });
  }

  async selectOrganisation(): Promise<void> {
    this.loading = true;
    this.isOrganisationAdmin = true;
    await this.organisationRoleService.setCurrentRole(this.selectedOrganisation.value);
    // this.selectedOrganisationName.setValue(this.selectedOrganisation.value.organisationName);

    try {
      this.selectedOrgCustomer = await this.subscriptionService.getOrganisationCustomer(this.selectedOrganisation.value.organisationId);
      await this.refreshProductsAndSubs();
    } catch (error) {
      if (error.status === 403 && (error?.statusText === 'Forbidden' || error?.error?.error === 'Forbidden')) {
        this.loading = false;
        this.isOrganisationAdmin = false;
      }
    }
  }

  async createSubscription(): Promise<void> {
    try {
      const checkedPrices: string[] = [];
      this.productList.forEach((product) => {
        if (product.suvoChecked) {
          checkedPrices.push(product.price.id);
        }
      });
      const createSubResponse = await this.subscriptionService.createSubscription(
        checkedPrices,
        this.selectedOrganisation.value.organisationId,
        this.seats.value
      );
      this.clientSecret = createSubResponse.clientSecret;
      if (!this.clientSecret) {
        this.usersSnackbarService.open('Your subscription has been created');
        await this.refreshProductsAndSubs();
      } else {
        this.enteringPaymentInfo = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async confirmCardPayment(): Promise<void> {
    try {
      // TODO: HARDCODED
      this.stripeService.confirmCardPayment(this.clientSecret, {
        payment_method: {
          card: this.card.element,
          billing_details: {
            name: 'Lewis Test',
            email: 'payee-email@test.com'
          },
        },
      }).pipe(take(1)).subscribe(async (res) => {
        this.clientSecret = null;
        this.usersSnackbarService.open('Your subscription has been created');
        this.enteringPaymentInfo = false;
        await this.refreshProductsAndSubs();
      }, (err) => {
        console.log(err);
      });
    } catch (error) {
      console.log(error);
    }
  }

  // async gotoCustomerPortal(): Promise<void> {
  //   try {
  //     const newPortalSession = await this.subscriptionService.createCustomerPortalSession(this.selectedOrganisation.value.organisationId, 'personal');
  //     if (newPortalSession) {
  //       window.location.href = newPortalSession.url;
  //     }
  //     console.log(newPortalSession);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // TODO: How to manage updating and creating with new checkbox system?
  async updateSubscription(): Promise<void> {
    try {
      // TODO: Assuming user cannot have more than 1 subscription
      const checkedPrices: string[] = [];
      this.productList.forEach((product) => {
        if (product.suvoChecked) {
          checkedPrices.push(product.price.id);
        }
      });
      await this.subscriptionService.updateSubscription(
        checkedPrices,
        this.selectedOrganisation.value.organisationId,
        this.selectedOrgCustomerSubs[0].id,
        this.seats.value
      );
      this.usersSnackbarService.open('Your subscription has been updated');
      await this.refreshProductsAndSubs();
    } catch (error) {
      console.log(error);
    }
  }

  async cancelSubscription(): Promise<void> {
    try {
      // TODO: BAD, assuming user cannot have more than 1 subscription
      await this.subscriptionService.cancelSubscription(this.selectedOrgCustomerSubs[0].id, this.selectedOrganisation.value.organisationId);
      this.usersSnackbarService.open('Your subscription has been cancelled');
      await this.refreshProductsAndSubs();
    } catch (error) {
      console.log(error);
    }
  }

  async refreshProductsAndSubs(): Promise<void> {
    this.loading = true;
    let tempSeats = 1;
    if (this.selectedOrgCustomer?.id) {
      this.selectedOrgCustomerSubs = (
        await this.subscriptionService.getActiveCustomerSubscriptions(this.selectedOrganisation.value.organisationId)
      ).data;

      this.productList = await this.subscriptionService.getProductList(this.selectedOrganisation.value.organisationId);

      if (this.productList.length) {
        this.dirtyCheckObj.originalValues.selectedProducts = [];
        this.dirtyCheckObj.isDirty = false;
      }
      this.productList.forEach((product) => {
        if (this.selectedOrgCustomerSubs.length) {
          // TODO: Not the best but this value should *never* be null
          tempSeats = this.selectedOrgCustomerSubs[0].items.data[0].quantity;
          for (const subscriptionItem of this.selectedOrgCustomerSubs[0]?.items.data) {
            if (subscriptionItem.price.id === product.price.id) {
              product.suvoChecked = true;
              break;
            } else {
              product.suvoChecked = false;
            }
          }
        } else {
          product.suvoChecked = false;
        }
        this.dirtyCheckObj.originalValues.selectedProducts.push(product.suvoChecked);
      });
      this.seats.setValue(tempSeats);
      this.dirtyCheckObj.originalValues.seats = this.seats.value;
      // TODO; Shouldn't need this extra call, but the HTML calls the method BEFORE the seats value changes....
      this.checkIfDirty();
      this.reCalculatePrice();
      this.loading = false;
    }
  }

  // TODO: Lags on high numbers, capped seat count for the time being
  reCalculatePrice(): void {
    let tempPrice = 0;
    this.productList.forEach((product) => {
      if (product.suvoChecked) {
        if (product.price.tiers.length) {

          // TODO: I tried...

          // let remainingSeats = this.seats.value;
          // let usedSeats = 0;
          // product.price.tiers.forEach(tier => {
          //   if (((remainingSeats - (tier.up_to - usedSeats)) < 0) || tier.up_to === null) {
          //     // If not enough seats left for the whole tier
          //     tempPrice += (tier.unit_amount * remainingSeats);
          //     // usedSeats += remainingSeats;
          //     usedSeats += (tier.up_to - usedSeats); // a
          //   } else {
          //     // If enough for the whole tier
          //     tempPrice += (tier.unit_amount * (tier.up_to - usedSeats));
          //     usedSeats += (tier.up_to - usedSeats); // a
          //   }
          //   remainingSeats -= usedSeats;
          //   // usedSeats += tier.up_to;
          //   console.log('REMAIN BEFORE', remainingSeats);
          //   if (remainingSeats < 0) {
          //     remainingSeats = 0;
          //   }
          //   console.log('REMAIN AFTER', remainingSeats);
          //   console.log('USED SEATS', usedSeats);
          // });

          tempPrice += this.calcProductTiersPrice(product.price.tiers, this.seats.value);
        } else {
          tempPrice += (product.price.unit_amount * this.seats.value);
        }
      }
    });
    this.previewPrice = tempPrice;
  }

  calcProductTiersPrice(tiers, startingSeats): number {
    let seatsLeft = startingSeats;
    let sum = 0;
    let previousUpTo = 0;

    for (const tier of tiers) {
      if (tier.up_to == null) {

        // We're at the end, just add the remaining seats
        sum += tier.unit_amount * seatsLeft;

      } else if (tier.up_to < startingSeats) {

        // How many seats we're adding from this chunk
        const amount = (tier.up_to - previousUpTo);

        // Update the sum by the correct amount
        sum += tier.unit_amount * amount;
        seatsLeft -= amount;
      } else {
        // If we go in here, we arent at the end (up_to == null) but we are out of seats
        sum += tier.unit_amount * seatsLeft;
        break;
      }
      // Use this to track how many seats are in a tier
      previousUpTo = tier.up_to;
    }
    return sum;
  }

  // TODO: Optimise?
  productSelectedCheck(): boolean {
    let oneBoxTicked = false;
    this.productList.forEach((product) => {
      if (product.suvoChecked) {
        oneBoxTicked = true;
      }
    });
    return oneBoxTicked;
  }

  checkIfDirty(): void {
    let tempDirty = false;
    this.dirtyCheckObj.originalValues.selectedProducts.forEach((originalValue, index) => {
      if (originalValue !== this.productList[index].suvoChecked) {
        tempDirty = true;
      }
    });

    if (this.dirtyCheckObj.originalValues.seats !== this.seats.value) {
      tempDirty = true;
    }
    this.dirtyCheckObj.isDirty = tempDirty;
  }

  ngOnDestroy(): void {
    if (this.seatsValueSub) {
      this.seatsValueSub.unsubscribe();
    }
  }
}
