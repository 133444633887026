import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PatientService} from '../../../../services/patient/patient.service';

@Component({
  selector: 'app-confirm-delete-patient-dialog',
  templateUrl: './confirm-delete-patient-dialog.component.html',
  styleUrls: ['./confirm-delete-patient-dialog.component.scss']
})
export class ConfirmDeletePatientDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeletePatientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
  }

  async deletePatient() {
    let deleted = false;

    try {
      await this.patientService.deletePatient(this.data.patientId);

      deleted = true;
    } catch (e) {
      alert(JSON.stringify(e));
    }

    this.dialogRef.close({deleted});
  }
}
