import { Component } from '@angular/core';
import { IconMessageComponent } from '../icon-message/icon-message.component';

@Component({
  selector: 'suvo-core-error-message',
  templateUrl: '../icon-message/icon-message.component.html',
  styleUrls: ['../icon-message/icon-message.component.scss']
})
export class ErrorMessageComponent extends IconMessageComponent {
  icon = 'warning';
  constructor() {
    super();
  }
}
