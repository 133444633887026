import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { SharedModule } from '../../shared/shared.module';
import { BreadcrumbsService } from './services/breadcrumbs.service';

@NgModule({
  declarations: [
    BreadcrumbsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    BreadcrumbsService,
  ],
  exports: [
    BreadcrumbsComponent,
  ]
})
export class BreadcrumbsModule { }
