import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiService } from '@suvo-bi-core';
import { TableService } from '../../../shared/table/services/table.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends TableService {

  constructor(protected httpClient: HttpClient, private apiService: ApiService) {
    super(httpClient, 'patient');
  }

  async checkUserPinInfo(patientInfo): Promise<any> {
    return super.post(`${environment.usersServiceApi}patient/verify-pin`, patientInfo)
  }

  // TODO: set type to stripped-down user profile
  async getAllPatients(): Promise<any> {
    return super.get(`${environment.usersServiceApi}patient`);
  }

  // TODO: pending proper endpoint assignent to '/patient/'
  async getPatientPin(targetUid: string): Promise<{ pin: string, isNew: boolean }> {
    return (await this.apiService.get(`${environment.usersServiceApi}me/pin/${targetUid}`));
  }

  async deletePatient(uid: string){
    if(uid) {
      return (await this.apiService.delete(`${environment.usersServiceApi}patient/admin/${uid}`));
    } else {
      throw new Error("uid not provided.");
    }
  }
}
